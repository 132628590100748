/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Card, Col, Row, Container, } from "react-bootstrap";
import axios from "axios";
import Header from "../Layout/Header/Header";
import Footer from "../Layout/Footer/Footer";
import "../../index.css";

function Profesionales() {
  const DOCGET = `${process.env.REACT_APP_API}doctorslibre`;
  let [doctores, setDoctores] = useState([])

  useEffect(() => {
    axios
      .get(DOCGET)
      .then((data) => { setDoctores(data.data) })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, []);
  
  return (
    <>
      <Header />
      <Container>
        <Row>
          {doctores.map((doctor) => (
            <Col xs={12} md={6} lg={4}>
              <div className="scene">
                <div className="card">
                  <Card className="card__face card__face--front" style={{ width: '18rem' }}>
                    <Card.Img className="odontologosCards" variant="top" src={`https://res.cloudinary.com/jacobozonana/image/upload/v1673487530/Odin/${doctor._id}.jpg`} />
                    <Card.Body>
                      <Card.Title>{`${doctor.nombre} ${doctor.apellido}`}</Card.Title>
                    </Card.Body>
                  </Card>

                  <Card className="card__face card__face--back" style={{ width: '18rem' }}>
                    <Card.Img className="odontologosCards" variant="top" src={`https://res.cloudinary.com/jacobozonana/image/upload/v1673487530/Odin/${doctor._id}.jpg`} />
                    <Card.Body>
                      <Card.Text>
                        Especialidades:
                        <br />
                        {doctor.especialidad.map((item) => ` ${item} `)}
                        <br />
                        Cobertura:
                        {/* <i className="fa-regular fa-clock"></i>
                  <i className="far fa-clock"></i> */}
                        <br />
                        {doctor.obraSocialAtendida.map((item) => ` ${item} `)}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
      {/* <Footer /> */}
    </>
  )
}

export default Profesionales