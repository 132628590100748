/* eslint-disable */
import React, { useState, useContext, useLayoutEffect } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import { Container, Button, Form } from "react-bootstrap";
import Header from "../Layout/Header/Header";
import Footer from "../Layout/Footer/Footer";
import axios from "axios";
import Swal from "sweetalert2";
import "../../index.css";

const SignupDoctor = () => {
  const { user1, isAuth } = useContext(AuthContext);
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [dni, setDni] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [especialidad, setEspecialidad] = useState("");
  const [celular, setCelular] = useState("");
  const history = useHistory();
  let [obras, setObras] = useState([]);
  let [obrasEscogidas, setObrasEscogidas] = useState([]);
  let [a, setA] = useState([]);
  let [especialidades, setEspecialidades] = useState([]);
  let [especialidadesEscogidas, setEspecialidadesEscogidas] = useState([]);
  let [limiteCitasPaciente, setLimiteCitasPaciente] = useState();
  let [limiteEdad, setLimiteEdad] = useState();
  let [limiteEdadInferior, setLimiteEdadInferior] = useState();

  useLayoutEffect(() => {
    axios.get(`${process.env.REACT_APP_API}obrassociales/`)
      .then((resDB) => setObras(resDB.data))
      .catch((error) => console.log(error))

    axios.get(`${process.env.REACT_APP_API}especialidades/`)
      .then((resDB) => setEspecialidades(resDB.data))
      .catch((error) => console.log(error))
  }, [])

  function obraEscogida(event) {
    let temp = event
    const index = obrasEscogidas.map((obra) => obra.nombre).includes(event);
    index ? setObrasEscogidas(obrasEscogidas.filter(obra => obra.nombre != event)) : obrasEscogidas.push({ nombre: temp, limite: 30 });
    setA(Math.random())
  }

  function especialidadEscogida(event) {
    let temp = event
    const index = especialidadesEscogidas.includes(event);
    index ? setEspecialidadesEscogidas(especialidadesEscogidas.filter(hora => hora != event)) : especialidadesEscogidas.push(temp);
    setA(Math.random())
  }

  const handleForm = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      Swal.fire({
        icon: "error",
        title: "Verifica tu contraseña",
        allowEscapeKey: true,
      });
    } else {
      const jsonSend = {
        role: "doctor",
        nombre,
        apellido,
        dni,
        obraSocialAtendida: obrasEscogidas,
        email,
        password: dni,
        especialidad: especialidadesEscogidas,
        celular,
        limiteCitasPaciente,
        limiteEdad,
        limiteEdadInferior
      };

      const SIGNUP_URL = `${process.env.REACT_APP_API}signupdoctor/${user1.id}`;
      try {
        await axios.post(SIGNUP_URL, jsonSend, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        });
        setNombre("");
        setApellido("");
        setEmail("");
        setPassword("");
        setEspecialidad("");
        setCelular("");
        Swal.fire({
          icon: "success",
          title: "Doctor creado con exito",
          timer: 1000,
          timerProgressBar: true,
          allowEscapeKey: true,
        }).then(history.push("/doctorslist"));
      } catch (error) {
        console.log(error);
        let message = error.response.data.message
        Swal.fire({
          icon: "error",
          title: "No se puede crear doctor ",
          text: "Algo salio mal, " + message,
          timer: 3000,
          timerProgressBar: true,
          allowEscapeKey: true,
        });
      }
    }
  };

  return (
    <>
      {isAuth ? (
        user1.role === "admin" ? (
          <>
            <Header />
            <Container className="themed-container" fluid={true}>
              <h1 className="mb-4 reg">Registra un doctor nuevo</h1>
              <Form className="container form-regis" onSubmit={handleForm}>
                <Form.Group>
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control
                    value={nombre}
                    onChange={(e) => setNombre(e.target.value)}
                    type="text"
                    placeholder="Escribe tu nombre"
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Apellido</Form.Label>
                  <Form.Control
                    value={apellido}
                    onChange={(e) => setApellido(e.target.value)}
                    type="text"
                    placeholder="Escribe tu apellido"
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Dni</Form.Label>
                  <Form.Control
                    value={dni}
                    onChange={(e) => setDni(e.target.value)}
                    type="text"
                    placeholder="Dni"
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder="Correo electronico"
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Celular</Form.Label>
                  <Form.Control
                    value={celular}
                    onChange={(e) => setCelular(e.target.value)}
                    type="number"
                    placeholder="Teléfono"
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Limite semanal de turnos por paciente</Form.Label>
                  <Form.Control
                    style={{ maxWidth: "20vw" }}
                    value={limiteCitasPaciente}
                    onChange={(e) => setLimiteCitasPaciente(e.target.value)}
                    type="number"
                    placeholder="Limite de citas por paciente"
                    required
                  />
                </Form.Group>
                <Form.Label>Limites de edad de cada paciente</Form.Label>
                <div className="row">

                  <Form.Group className="col" style={{ maxWidth: "20vw", marginLeft: "50px" }}>
                    <Form.Control
                      value={limiteEdadInferior}
                      onChange={(e) => setLimiteEdadInferior(e.target.value)}
                      type="number"
                      placeholder="Limite de edad inferior"
                      required
                    />
                  </Form.Group>

                  <Form.Group className="col" style={{ maxWidth: "20vw" }}>
                    <Form.Control
                      value={limiteEdad}
                      onChange={(e) => setLimiteEdad(e.target.value)}
                      type="number"
                      placeholder="Limite de edad superior"
                      required
                    />
                  </Form.Group>

                </div>

                <Form.Group>
                  <Form.Label>Obras sociales que atendera</Form.Label>
                  <br />
                  {obras.map((item, i) => (
                    <Button size="sm" className="margin" style={{ "minWidth": "7vw" }} id={item.nombre} value={item.nombre} onClick={(event) => obraEscogida(event.target.value)} variant={obrasEscogidas.map((obra) => obra.nombre).includes(item.nombre) ? "info" : "outline-info"}>{item.nombre}</Button>
                  ))}
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    Especialidades que atendera
                  </Form.Label>
                  <br />
                  {especialidades.map((item, i) => (
                    <Button size="sm" className="margin" style={{ "minWidth": "7vw" }} id={item.nombre} value={item.nombre} onClick={(event) => especialidadEscogida(event.target.value)} variant={especialidadesEscogidas.includes(item.nombre) ? "info" : "outline-info"}>{item.nombre}</Button>
                  ))}
                </Form.Group>
                {/* <Form.Group>
                    <Form.Label>Contraseña</Form.Label>
                    <Form.Control
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      placeholder="Escribe tu contraseña"
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Verificar contraseña</Form.Label>
                    <Form.Control
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      type="password"
                      placeholder="Escribe tu contraseña"
                      required
                    />
                  </Form.Group> */}
                <Button variant="info" type="submit">
                  Enviar
                </Button>
              </Form>
            </Container>
            {/* <Footer /> */}
          </>
        ) : undefined
      ) : undefined}
    </>
  );
};

export default SignupDoctor;
