/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Form, Button, Modal, Col } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import "../../index.css";
import dayjs from 'dayjs'
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import getDay from "date-fns/getDay";
import es from 'date-fns/locale/es';
registerLocale('es', es)

function BlockApointment() {
  const { user1, isAuth } = useContext(AuthContext);
  const DOCGET = `${process.env.REACT_APP_API}doctors/${user1.id}`;
  const [schedule, setSchedule] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [date, setDate] = useState("Fecha");
  const [startDate, setStartDate] = useState(null);
  const [time, setTime] = useState("Hora");
  const [note, setNote] = useState("Escribe la razón");
  const [user] = useState(user1.id);
  const [usrdates, setUsrdates] = useState([]);
  const [usdat, setUsdat] = useState([]);
  const [fecha, setFecha] = useState("");
  const [data, setData] = useState([]);
  const [display, setDisplay] = useState("off");
  const [es, setes] = useState(["09:00", "09:15", "09:30", "09:45", "10:00", "10:15", "10:30", "10:45", "11:00", "11:15", "11:30", "11:45", "12:00", "12:15", "12:30", "12:45", "13:00", "13:15", "13:30", "13:45", "14:00", "14:15", "14:30", "14:45", "15:00", "15:15", "15:30", "15:45", "16:00", "16:15", "16:30", "16:45", "17:00", "17:15", "17:30", "17:45", "18:00"]);
  const [borbot, setBorbot] = useState([]);
  const [hours, setHours] = useState([]);
  const [apa, setApa] = useState("btn btn-info  apagado");
  const [sinHoras, setSinHoras] = useState(false);
  const excludeColumns = ["_id", "is_active", "createdAt", "updatedAt"]; // excluye datos del arreglo del filtro
  const SCHDOCGET = `${process.env.REACT_APP_API}schedulesbydoctor/${user1.id}/${user1.id}`;
  const SCHUSRGET = `${process.env.REACT_APP_API}schedulesbyuser/${user1.id}/${user1.id}`;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let daysOff = [] // este array debera estar guaradado en cada doctor para excluir esos dias [0,6]

  useEffect(() => {
    axios
      .get(SCHUSRGET, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((data) => setUsrdates(data.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get(DOCGET, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((data) => setDoctors(data.data))
      .catch((err) => console.log(err));
  }, [usrdates]);

  useEffect(() => {
    axios
      .get(SCHDOCGET, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((data) => setSchedule(data.data))
      .catch((err) => console.log(err));
  }, []);

  const diaSeleccionado = (selectedDay) => {
    setSinHoras(false);
    setes(["09:00", "09:15", "09:30", "09:45", "10:00", "10:15", "10:30", "10:45", "11:00", "11:15", "11:30", "11:45", "12:00", "12:15", "12:30", "12:45", "13:00", "13:15", "13:30", "13:45", "14:00", "14:15", "14:30", "14:45", "15:00", "15:15", "15:30", "15:45", "16:00", "16:15", "16:30", "16:45", "17:00", "17:15", "17:30", "17:45", "18:00"]);
    setBorbot([""]);
    setApa("btn btn-info ");
    setDisplay("");
    setTime("Hora");
    setHours([]);
    setStartDate(selectedDay)
    setDate(`${dayjs(selectedDay).format("YYYY-MM-DD")}T`)
    filterData(`${dayjs(selectedDay).format("YYYY-MM-DD")}T`)
  };

  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") setData(schedule);
    else {
      const filteredData = schedule.filter((item) => {
        return Object.keys(item).some((key) =>
          excludeColumns.includes(key)
            ? false
            : String(item[key]).toLowerCase().includes(lowercasedValue)
        );
      });
      setData(filteredData);
    }
  };

  useEffect(() => {
    data.map((info) => borbot.push(info.time));
    const disponibles = es.filter((item) => !borbot.includes(item));

    if (disponibles.length === 0) {
      setes(disponibles);
      setSinHoras(true);
    } else {
      setes(disponibles);
    }
  }, [data]);

  useEffect(() => {
    let usdates = [];
    usrdates.map((info) => usdates.push(info.date));
    let year = usdates.map((v) => parseInt(v.slice(0, 4)));
    let month = usdates.map((v) => parseInt(v.slice(5, 7)));
    let day = usdates.map((v) => parseInt(v.slice(8, 10)));
    let datos = [];

    for (var i = 0; i < year.length; i++) {
      datos.push({
        year: year[i],
        month: month[i],
        day: day[i],
      });
      setUsdat(datos);
    }
  }, [usrdates]);

  const saveDate = () => {
    for (let i = 0; i < hours.length; i++) {
      const SCHPOST1 = `${process.env.REACT_APP_API}scheduleblock/${user1.id}`;
      let x = date.slice(0, 11);
      const date1 = `${x}${hours[i]}`;
      axios
        .post(
          SCHPOST1,
          {
            type: false,
            date: date1,
            time: hours[i],
            note,
            user: user,
            profesional: user1.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          Swal.fire({
            allowEscapeKey: true,
            icon: "success",
            title: "Listo!",
            confirmButtonText: `Ok`,
            timer: 1000,
            timerProgressBar: true,
          }).then(() => {
            window.location.reload();
          });
        })
        .catch((error) => {
          let message = error.response.data.message;
          Swal.fire({
            allowEscapeKey: true,
            icon: "error",
            title: "Oops...",
            text: "Lo sentimos esta acción no se pudo completar " + message,
          });
          console.log(error);
        });
    }
  };

  const saveDay = () => {
    for (let i = 0; i < es.length; i++) {
      const SCHPOST1 = `${process.env.REACT_APP_API}scheduleblock/${user1.id}`;
      let x = date.slice(0, 11);
      const date1 = `${x}${es[i]}`;
      axios
        .post(
          SCHPOST1,
          {
            type: false,
            date: date1,
            time: es[i],
            note,
            user: user,
            profesional: user1.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          Swal.fire({
            allowEscapeKey: true,
            icon: "success",
            title: "Listo!",
            confirmButtonText: `Ok`,
            timer: 1000,
            timerProgressBar: true,
          }).then(() => {
            window.location.reload();
          });
        })
        .catch((error) => {
          let message = error.response.data.message;
          Swal.fire({
            allowEscapeKey: true,
            icon: "error",
            title: "Oops...",
            text: "Lo sentimos esta acción no se pudo completar  " + message,
          });
          console.log(error);
        });
    }
  };

  const isWeekday = (date) => {
    const day = getDay(date);
    return !daysOff.some(dayOff => {
      if (day == dayOff) {
        return true;
      }
      return false;
    })
  };

  return (
    <>
      {isAuth ? (
        user1.role === "doctor" ? (
          <div>
            <Button
              variant="success"
              onClick={handleShow}
            >
              Quiero horas libres!
            </Button>
            <Modal show={show} size="sm" onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Escoge dia y horas</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Col>
                    <Form.Group>
                      <DatePicker calendarStartDay={0} includeDateIntervals={[{ start: new Date(), end: new Date(dayjs().add(1, 'year')) },]} locale="es" todayButton="Hoy" className="picker" placeholderText="Selecciona la Fecha" selected={startDate} dateFormat="dd/MM/yyyy" filterDate={isWeekday} onChange={(date) => diaSeleccionado(date)} />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      {sinHoras ? (
                        <>
                          <h6 className="CitaSeleccionada sinhoras">
                            Ups! El dia esta lleno.<br></br>Escoge otro dia por
                            favor
                          </h6>
                        </>
                      ) : (
                        <>
                          <div className={display}>
                            {es.map((hora, i) => (
                              <Button
                                variant="outline-info"
                                className="margin"
                                size="sm"
                                key={i}
                                onClick={() => {
                                  setHours((hours) =>
                                    hours.filter((item) => item !== hora)
                                  );
                                  setHours((hours) => hours.concat(hora));
                                }}
                              >
                                {hora}
                              </Button>
                            ))}
                            <div className="hours">
                              <p className="hours1">{`${hours} `}</p>
                            </div>
                            <div className="floatl">
                              <Form.Text className="text-muted">
                                Horas seleccionadas
                              </Form.Text>
                            </div>

                            <div className="float">
                              <Button
                                variant="outline-dark"
                                size="sm"
                                onClick={() => {
                                  setHours([]);
                                  setDate("Fecha");
                                  setDisplay("off");
                                }}
                              >
                                Reset
                              </Button>
                            </div>
                          </div>
                        </>
                      )}
                    </Form.Group>
                  </Col>
                  <Form.Group>
                    <textarea
                      className="note"
                      placeholder={note}
                      rows="3"
                      onChange={(e) => {
                        setNote(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  type="submit"
                  onClick={() => {
                    saveDay();
                  }}
                  variant="outline-danger"
                >
                  Todo el día libre!
                </Button>
                <Button
                  type="submit"
                  onClick={() => {
                    saveDate();
                  }}
                  className="btn btn-info"
                >
                  Horas líbres!
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        ) : undefined
      ) : undefined}
    </>
  );
}

export default BlockApointment;
