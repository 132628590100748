import React, { useState } from "react";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";

function Note(props) {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const [noteonmodal, setNoteonmodal] = useState("");

  return (
    <>
      <OverlayTrigger
        placement="top" // La posición del tooltip (puede ser "top", "bottom", "left", "right")
        overlay={<Tooltip id="button-tooltip">Notas sobre el turno</Tooltip>}
      >
        <Button
          variant={props.note !== "Escribe aqui algun comentario a tu cita" ? "warning" : "danger"}
          onClick={() => ((handleShow(), setNoteonmodal(props.note)))}
        >
          <i className="far fa-sticky-note"></i>

        </Button>
      </OverlayTrigger>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Nota</Modal.Title>
        </Modal.Header>
        <Modal.Body>{noteonmodal}</Modal.Body>
      </Modal>
    </>
  );
}

export default Note;
