/* eslint-disable */
import React, { useEffect, useState, useContext, useLayoutEffect } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Button, Table, Dropdown, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { TableVirtuoso } from 'react-virtuoso'
import DatePicker, { registerLocale } from "react-datepicker";
import DeleteSchedule from "../Delete/DeleteSchedule";
import Note from "../Note/Note";
import axios from "axios";
import exportFromJSON from "export-from-json";
import dayjs from 'dayjs'
import es from 'date-fns/locale/es';
import DeleteAllDatesByDoctor from "../Delete/DeleteAllDatesByDoctor";
import Expediente from "../Formulario/Expediente";
import Apointment from "../Apointment/Apointment";
import ReactLoading from 'react-loading'
import Select from 'react-select'
import "react-datepicker/dist/react-datepicker.css";
import "./ScheduleList.css"
import "../../index.css";
registerLocale('es', es)

function ScheduleList() {
  const { user1, isAuth } = useContext(AuthContext);
  let URL_GET_SCHEDULE = `${process.env.REACT_APP_API}${user1.role == "secre" ? "schedulesLight" : user1.role == "admin" ? "schedulesLight" : user1.role == "doctor" ? "schedulesbydoctor" : user1.role == "user" ? "schedulesbyuser" : null}/${user1.id}/${user1.role == "doctor" || user1.role == "user" ? user1.id : ""}`;
  let SCHEDULEPATCH = `${process.env.REACT_APP_API}asistencia`;
  let USERPATCH = `${process.env.REACT_APP_API}editusers`;
  let [loading, setLoading] = useState(false)
  let [data1, setData1] = useState([]);
  let [data1OG, setData1OG] = useState([]);
  let [searchText, setSearchText] = useState("");
  let [dataxls, setDataxls] = useState([]);
  let [show, setShow] = useState(false)
  let [citasUsuario, setCitasUsuario] = useState([])
  let [pacientesMenu, setPacientesMenu] = useState([])
  let [doctoresMenu, setDoctoresMenu] = useState([])
  let [filtroAcumulado, setFiltroAcumulado] = useState({})
  let handleClose = () => setShow(false);
  let handleShow = () => setShow(true);
  let excludeColumns = ["is_active", "createdAt", "updatedAt"];
  let data = dataxls;
  let fileName = "MisTurnos";
  let exportType = "csv";

  useLayoutEffect(() => {
    setLoading(true)
    if (user1.role != {}) {
      axios.get(URL_GET_SCHEDULE, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
        .then((data) => {

          // ordenar las citas por fecha y hora, pero hay que concatenar la fecha y la hora para que se ordene bien con dayjs
          let sortedData = data.data.sort((a, b) => dayjs(`${a.date.split('T')[0]}${a.time}`).$d - dayjs(`${b.date.split('T')[0]}${b.time}`).$d)

          if (user1.role == "user") {
            // filtrar solo los turnos futuros
            sortedData = sortedData.filter(x => dayjs(`${x.date.split('T')[0]}${x.time}`).$d >= dayjs().$d)
          }

          // todo esto es para el filtro de pacientes
          let pacientes = []

          sortedData.map((item) => {
            pacientes.push({ _id: item.user[0]._id, nombre: `${item.user[0].nombre} ${item.user[0].apellido}`, })
          })

          let pacientesUnicos = pacientes.filter((v, i, a) => a.findIndex(t => (t._id === v._id)) === i)

          pacientesUnicos = pacientesUnicos.map((item) => {
            return { _id: item._id, nombre: item.nombre.replace("  ", " ").trim().toLowerCase().replace(/^\w/, c => c.toUpperCase()) }
          })

          pacientesUnicos = pacientesUnicos.sort((a, b) => (a.nombre > b.nombre ? 1 : -1))

          let tempPacientes = []

          pacientesUnicos.forEach((paciente) => { tempPacientes.push({ value: paciente._id, label: paciente.nombre }) })

          // todo esto es para el filtro de doctor
          let doctores = []

          sortedData.map((item) => {
            doctores.push({ _id: item.profesional[0]._id, nombre: `${item.profesional[0].nombre} ${item.profesional[0].apellido}` })
          })

          let doctoresUnicos = doctores.filter((v, i, a) => a.findIndex(t => (t._id === v._id)) === i)

          doctoresUnicos = doctoresUnicos.map((item) => {
            return { _id: item._id, nombre: item.nombre.replace("  ", " ").trim().toLowerCase().replace(/^\w/, c => c.toUpperCase()) }
          })

          doctoresUnicos = doctoresUnicos.sort((a, b) => (a.nombre > b.nombre ? 1 : -1))

          let tempDoctores = []

          doctoresUnicos.forEach((doctor) => { tempDoctores.push({ value: doctor._id, label: doctor.nombre }) })

          // incluir opcion de eliminar filtro en ambos filtros
          tempPacientes.unshift({ value: "eliminar", label: "Eliminar filtro" })
          tempDoctores.unshift({ value: "eliminar", label: "Eliminar filtro" })

          setPacientesMenu(tempPacientes)
          setDoctoresMenu(tempDoctores)
          setData1(sortedData)
          setData1OG(sortedData)
          setLoading(false)

        })
        .catch((err) => console.log(err));

    }
  }, []);

  const filterData = (value, filAcumulado) => {

    let temp = []

    let resultadoFiltrado = data1OG.filter((item) => {
      for (let key in filAcumulado) {
        if (key == "_id-paciente") {
          if (item.user[0]._id != filAcumulado[key]) return false
        }
        if (key == "_id-profesional") {
          if (item.profesional[0]._id != filAcumulado[key]) return false
        }
      }
      return true
    });

    temp = resultadoFiltrado

    const lowercasedValue = value ? value.toLowerCase().trim() : "";

    if (lowercasedValue === "") { setData1(temp) }
    else {
      let filteredData = temp.filter((item) => {
        return Object.keys(item).some((key) => excludeColumns.includes(key) ? false : dayjs(item.date).isSame(dayjs(lowercasedValue), 'day'));
      });
      setData1(filteredData);

    }
  };

  const xls = () => {
    exportFromJSON({ data, fileName, exportType });
  };

  useEffect(() => {

    let data2 = [];

    data1.map((date) => {
      data2.push(date);
    });

    let inf = data2.filter((v) => v.type === true);
    let date = inf.map((v) => v.date.split("T")[0]);
    let usefna = inf.map((v) => v.user[0].nombre);
    let uselna = inf.map((v) => v.user[0].apellido);
    let tel = inf.map((v) => v.user[0].tel);
    let time = inf.map((v) => v.time);
    let docfna = inf.map((v) => v.profesional[0].nombre);
    let doclna = inf.map((v) => v.profesional[0].apellido);

    let datos = [];

    for (var i = 0; i < date.length; i++) {
      datos.push({
        Fecha: date[i],
        Hora: time[i],
        Nombre: usefna[i],
        Apellido: uselna[i],
        Telefono: tel[i],
        NombreDr: docfna[i],
        ApellidoDr: doclna[i],
      });
      datos = datos.sort((a, b) => (a.Hora > b.Hora ? 1 : -1));
      setDataxls(datos);
    }
  }, [data1]);

  function checkCanceladas(citas) {
    let canceloReciente = []
    citas.map((item) => {
      if (dayjs(`${item.split(' ')[0]} ${item.split(' ')[1]}`).$d >= dayjs().subtract(3, 'month').$d) { canceloReciente.push(item) }
    })
    return canceloReciente.length
  }

  function siVino(idCita, idPaciente, index) {
    axios.patch(`${SCHEDULEPATCH}/${user1.id}/${idCita}`, { asistio: "si" },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        let indexCita = data1.findIndex(x => x._id == idCita)

        data1[indexCita].asistio = "si"

        setData1([...data1])
      })
      .catch((error) => console.log(error))

    axios.patch(`${USERPATCH}/${user1.id}/${idPaciente}`, { primeraCita: true, pacienteExistente: true },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then(() => { })
      .catch((error) => console.log(error))
  }

  function noVino(idCita, idPaciente, primeraVez) {
    axios.patch(`${SCHEDULEPATCH}/${user1.id}/${idCita}`, { asistio: "no" },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then(() => {
        let indexCita = data1.findIndex(x => x._id == idCita)

        data1[indexCita].asistio = "no"

        setData1([...data1])
      })
      .catch((error) => { })

    primeraVez ?
      axios.patch(`${USERPATCH}/${user1.id}/${idPaciente}`, { pacienteExistente: false, primeraCita: false },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((resDB) => { })
        .catch((error) => { })
      : null
  }

  function filtrarAcumulado(key, valor) {
    setFiltroAcumulado({ ...filtroAcumulado, [key]: valor })
  }

  function eliminarFiltroAcumulado(key) {
    let filtroAcumuladoTemp = { ...filtroAcumulado }
    delete filtroAcumuladoTemp[key]
    setFiltroAcumulado(filtroAcumuladoTemp)
  }

  useEffect(() => {
    filterData(searchText, filtroAcumulado)
  }, [searchText, filtroAcumulado])

  return (
    <div>

      <Modal size="xl" show={show}>
        <Modal.Header>
          <Modal.Title>Historial de turnos</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <div className="container">
            <div className="row">
              <div className="col-12">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Fecha</th>
                      <th scope="col">Hora</th>
                      <th scope="col">Profesional</th>
                      <th scope="col">Motivo</th>
                      <th scope="col">Estado</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      citasUsuario.map((cita, i) => (
                        <tr key={i}>
                          <th scope="row">{dayjs(cita.date).format("DD/MM/YYYY")}</th>
                          <td>{cita.time}</td>
                          <td>{cita.profesional[0].nombre.toLowerCase().replace(/^\w/, c => c.toUpperCase())} {cita.profesional[0].apellido.toLowerCase().replace(/^\w/, c => c.toUpperCase())}</td>
                          <td>{cita.note}</td>
                          <td>{cita.asistio == "si" ? "Realizada" : cita.asistio == "no" ? "Cancelada" : "Pendiente"}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </Modal.Body>

        <Modal.Footer><Button variant="secondary" onClick={handleClose}>Cerrar</Button></Modal.Footer>

      </Modal>

      {isAuth ? (
        <>

          <div className="filtrosFila">

            {user1.role == "user" ?

              <div className="picker">
                <Apointment />
              </div>

              :
              <>

                <div className="picker filtrosColumna" style={{ zIndex: 1000 }}>
                  <DatePicker calendarStartDay={0} locale="es" todayButton="Hoy" placeholderText={searchText ? searchText : "Escoge el dia"} dateFormat="dd/MM/yyyy" onChange={(date) => setSearchText(`${dayjs(date).format("YYYY-MM-DD")}`)} />
                  <Button onClick={() => setSearchText("")} variant="outline-danger rounded-circle"><i className="fas fa-times"></i></Button>
                </div>

                {/* boton para limpiar la fecha */}
                <div className="picker filtrosColumna">
                </div>

                <Select className='picker filtrosColumna' placeholder="Buscar pacientes" options={pacientesMenu} onChange={(e) => { e.value == "eliminar" ? eliminarFiltroAcumulado("_id-paciente") : filtrarAcumulado("_id-paciente", e.value); }} />

                {
                  !user1.role === "admin" ? null :
                    <Select className='picker filtrosColumna' placeholder="Buscar profesionales" options={doctoresMenu} onChange={(e) => { e.value == "eliminar" ? eliminarFiltroAcumulado("_id-profesional") : filtrarAcumulado("_id-profesional", e.value); }} />
                }

                <div className='picker filtrosColumna'>
                  <DeleteAllDatesByDoctor />
                </div>

                <div className="filtrosColumna">
                  {user1.role != "user" ?
                    <Button style={{ marginRight: "5px" }} variant="outline-success rounded-circle" onClick={xls}><i className="far fa-file-excel"></i></Button> : null}
                </div>

              </>
            }

          </div>

          <br />

          {loading ?
            <center>
              <div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div>
            </center>
            :
            <>
              {
                data1.length == 0 ? <center><h3>No hay turnos</h3></center> :
                  <TableVirtuoso
                    style={{ height: "68vh", zIndex: 0 }}
                    data={data1}
                    components={{ Table: ({ style, ...props }) => <Table hover {...props} style={{ ...style, width: '100%' }} /> }}
                    fixedHeaderContent={() => (
                      <tr>
                        <th style={{ textAlign: 'center', backgroundColor: 'white', zIndex: 0 }}>Fecha</th>
                        <th style={{ textAlign: 'center', backgroundColor: 'white', zIndex: 0 }}>Hora</th>
                        <th style={{ textAlign: 'center', backgroundColor: 'white', zIndex: 0 }}>Nombre</th>
                        <th style={{ textAlign: 'center', backgroundColor: 'white', zIndex: 0 }}>Apellido</th>
                        <th style={{ textAlign: 'center', backgroundColor: 'white', zIndex: 0 }}>Telefono</th>
                        <th style={{ textAlign: 'center', backgroundColor: 'white', zIndex: 0 }}>Nombre Dr.</th>
                        <th style={{ textAlign: 'center', backgroundColor: 'white', zIndex: 0 }}>Apellido Dr.</th>
                        <th style={{ textAlign: 'center', backgroundColor: 'white', zIndex: 0 }}>Cancelaciones</th>
                        <th style={{ textAlign: 'center', backgroundColor: 'white', zIndex: 0 }}>Asistencia</th>
                        <th style={{ textAlign: 'center', backgroundColor: 'white', zIndex: 0 }}></th>
                      </tr>
                    )}
                    itemContent={(index, date) => (
                      <>
                        <td style={date.asistio == "si" ? { "backgroundColor": "rgba(23, 240, 7,0.2)", textAlign: 'center', maxWidth: '3vw', minWidth: '3vw' } : date.asistio == "no" ? { "backgroundColor": "rgba(222, 29, 16, 0.2)", textAlign: 'center', maxWidth: '3vw', minWidth: '3vw', } : { textAlign: 'center', maxWidth: '3vw', minWidth: '3vw' }}>{dayjs(date.date).format('DD/MM/YY')}</td>
                        <td style={date.asistio == "si" ? { "backgroundColor": "rgba(23, 240, 7,0.2)", textAlign: 'center', maxWidth: '3vw', minWidth: '3vw' } : date.asistio == "no" ? { "backgroundColor": "rgba(222, 29, 16, 0.2)", textAlign: 'center', maxWidth: '3vw', minWidth: '3vw' } : { textAlign: 'center', maxWidth: '3vw', minWidth: '3vw' }}>{date.time}</td>
                        <td style={date.asistio == "si" ? { "backgroundColor": "rgba(23, 240, 7,0.2)", textAlign: 'center', maxWidth: '3vw', minWidth: '3vw' } : date.asistio == "no" ? { "backgroundColor": "rgba(222, 29, 16, 0.2)", textAlign: 'center', maxWidth: '3vw', minWidth: '3vw' } : { textAlign: 'center', maxWidth: '3vw', minWidth: '3vw' }}>{date.user[0].nombre.toLowerCase().replace(/^\w/, c => c.toUpperCase())}</td>
                        <td style={date.asistio == "si" ? { "backgroundColor": "rgba(23, 240, 7,0.2)", textAlign: 'center', maxWidth: '3vw', minWidth: '3vw' } : date.asistio == "no" ? { "backgroundColor": "rgba(222, 29, 16, 0.2)", textAlign: 'center', maxWidth: '3vw', minWidth: '3vw' } : { textAlign: 'center', maxWidth: '3vw', minWidth: '3vw' }}>{date.user[0].apellido.toLowerCase().replace(/^\w/, c => c.toUpperCase())}</td>
                        <td style={date.asistio == "si" ? { "backgroundColor": "rgba(23, 240, 7,0.2)", textAlign: 'center', maxWidth: '3vw', minWidth: '3vw' } : date.asistio == "no" ? { "backgroundColor": "rgba(222, 29, 16, 0.2)", textAlign: 'center', maxWidth: '3vw', minWidth: '3vw' } : { textAlign: 'center', maxWidth: '3vw', minWidth: '3vw' }}>{date.user[0].celular}</td>
                        <td style={date.asistio == "si" ? { "backgroundColor": "rgba(23, 240, 7,0.2)", textAlign: 'center', maxWidth: '3vw', minWidth: '3vw' } : date.asistio == "no" ? { "backgroundColor": "rgba(222, 29, 16, 0.2)", textAlign: 'center', maxWidth: '3vw', minWidth: '3vw' } : { textAlign: 'center', maxWidth: '3vw', minWidth: '3vw' }}>{date.profesional[0].nombre.toLowerCase().replace(/^\w/, c => c.toUpperCase())}</td>
                        <td style={date.asistio == "si" ? { "backgroundColor": "rgba(23, 240, 7,0.2)", textAlign: 'center', maxWidth: '3vw', minWidth: '3vw' } : date.asistio == "no" ? { "backgroundColor": "rgba(222, 29, 16, 0.2)", textAlign: 'center', maxWidth: '3vw', minWidth: '3vw' } : { textAlign: 'center', maxWidth: '3vw', minWidth: '3vw' }}>{date.profesional[0].apellido.toLowerCase().replace(/^\w/, c => c.toUpperCase())}</td>
                        <td style={date.asistio == "si" ? { "backgroundColor": "rgba(23, 240, 7,0.2)", textAlign: 'center', maxWidth: '3vw', minWidth: '3vw' } : date.asistio == "no" ? { "backgroundColor": "rgba(222, 29, 16, 0.2)", textAlign: 'center', maxWidth: '3vw', minWidth: '3vw' } : { textAlign: 'center', maxWidth: '3vw', minWidth: '3vw' }}>{checkCanceladas(date.user[0].listaCancelaciones)}</td>
                        <td style={date.asistio == "si" ? { "backgroundColor": "rgba(23, 240, 7,0.2)", textAlign: 'center', maxWidth: '3vw', minWidth: '3vw' } : date.asistio == "no" ? { "backgroundColor": "rgba(222, 29, 16, 0.2)", textAlign: 'center', maxWidth: '3vw', minWidth: '3vw' } : { textAlign: 'center', maxWidth: '3vw', minWidth: '3vw' }}><Button style={{ marginRight: "3px", minWidth: "3rem" }} disabled={dayjs() > dayjs(`${date.date.split('T')[0]}${date.time}`).add(8, 'hour') ? true : false} variant="success" onClick={() => siVino(date._id, date.user[0]._id, date.primeraVez)}><i className="fas fa-check"></i></Button><Button style={{ minWidth: "3rem" }} disabled={dayjs() > dayjs(`${date.date.split('T')[0]}${date.time}`).add(8, 'hour') ? true : false} variant="danger" onClick={() => noVino(date._id, date.user[0]._id, date.primeraVez)}><i className="fas fa-times"></i></Button>
                        </td>
                        <td style={date.asistio == "si" ? { "backgroundColor": "rgba(23, 240, 7,0.2)", textAlign: 'center', width: '12vw' } : date.asistio == "no" ? { "backgroundColor": "rgba(222, 29, 16, 0.2)", textAlign: 'center', width: '12vw' } : { textAlign: 'center', width: '12vw' }}>

                          <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip">Historial de turnos</Tooltip>}                    >
                            <Button onClick={() => {
                              handleShow()
                              let citasUsuario = data1.filter(x => x.user[0]._id == date.user[0]._id)
                              setCitasUsuario(citasUsuario)
                            }
                            }><i className="fa-solid fa-calendar-days"></i>
                            </Button>
                          </OverlayTrigger>

                          <Expediente user={date.user[0]} />

                          <Note note={date.note} />

                          <DeleteSchedule userId={date.user[0]._id} id={date._id} datee={date.date} timee={date.time} primeraVez={date.primeraVez} />

                        </td>
                      </>
                    )}
                  />
              }
            </>
          }
        </>
      ) : undefined}
    </div>
  );
}

export default ScheduleList;