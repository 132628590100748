/* eslint-disable */
import React, { useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Logo from "../../../Counselor/Logo.jpg";
import Login from "../../Users/Login";
import "../../../index.css";
import Swal from "sweetalert2";

const Header = () => {
  const { isAuth, user1 } = useContext(AuthContext);

  function pregunta() {

    Swal.fire({
      title: '¿Ya sos paciente de Odin?',
      icon: 'question',
      showCancelButton: true,
      width: '80%',
      confirmButtonColor: 'green',
      cancelButtonColor: 'red',
      cancelButtonText: 'No, nunca me atendí en Odin',
      confirmButtonText: 'Sí, ya me antendí en Odin',
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = "/register?new=false"
      } else {
        window.location.href = "/register?new=true"
      }
    })
  }

  return (
    <>
      {/* Topbar Start */}
      <div className="container-fluid bg-light ps-5 pe-0 d-none d-lg-block">
        <div className="row gx-0">
          <div className="col-md-6 text-center text-lg-start mb-2 mb-lg-0">
            <div className="d-inline-flex align-items-center">
              <small className="py-2">
                {
                  isAuth ?
                    <span style={{ fontWeight: "bold", fontSize: "17px" }}>
                      Hola {String(user1.nombre).toLowerCase().replace(/^\w/, c => c.toUpperCase())} {user1.apellido.toLowerCase().replace(/^\w/, c => c.toUpperCase())}
                    </span>
                    :
                    null
                  // <>
                  //   <i className="far fa-clock text-primary me-2" />
                  //   Horarios de atención: Lun - Vie : 6.00 am - 10.00 pm, Domingos Cerrado{" "}
                  // </>
                }

              </small>
            </div>
          </div>
          <div className="col-md-6 text-center text-lg-end">
            <div className="position-relative d-inline-flex align-items-center bg-primary text-white top-shape px-5">
              <div className="me-3 pe-3 border-end py-2">
                <p className="m-0">
                  <i className="fa fa-envelope-open me-2" />
                  info@consultoriosodin.com.ar
                </p>
              </div>
              <div className="py-2">
                <p className="m-0">
                  {/* <i className="fab fa-whatsapp"></i> */}
                  &nbsp;
                  {/* <i className="fa fa-phone-alt me-2" /> */}
                  
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Topbar End */}
      <nav className="navbar navbar-expand-lg bg-white navbar-light shadow-sm px-5 py-3 py-lg-0">
        <a href="/" className="navbar-brand p-0">
          <h1 className="m-0 text-primary">
            <img src={Logo} style={{ width: "7vw", height: "auto", margin: "5%" }} alt="" />
          </h1>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto py-0">
            {isAuth ?
              <>
                <a href="/" className={`nav-item nav-link ${window.location.href == "/" ? "active" : null}`}>
                  {user1.role === "admin" || user1.role === "secre" ? "Turnos" : "Mis turnos"}
                </a>
                {user1.role === "user" ? null :
                  <a href="/blocklist" className={`nav-item nav-link ${window.location.href.split('/')[3] == "blocklist" ? "active" : null}`}>
                    {user1.role === "admin" || user1.role === "secre" ? "Horas libres" : "Agenda"}
                  </a>}
                {user1.role === "admin" ?
                  <a href="/adminslist" className={`nav-item nav-link ${window.location.href.split('/')[3] == "adminslist" ? "active" : null}`}>
                    Administradores
                  </a>
                  : null}
                {user1.role === "admin" || user1.role === "doctor" || user1.role === "secre" ?
                  <a href="/userslist" className={`nav-item nav-link ${window.location.href.split('/')[3] == "userslist" ? "active" : null}`}>
                    Usuarios
                  </a>
                  : null}
                {user1.role === "admin" ?
                  <a href="/secreslist" className={`nav-item nav-link ${window.location.href.split('/')[3] == "secreslist" ? "active" : null}`}>
                    Secretarias
                  </a>
                  : null}
                <a href="/doctorslist" className={`nav-item nav-link ${window.location.href.split('/')[3] == "doctorslist" ? "active" : null}`}>
                  {user1.role === "doctor" || user1.role === "admin" || user1.role === "secre" ? "Doctores" : "Lista de doctores"}
                </a>
                {user1.role === "admin" || user1.role === "secre" ?
                  <>
                    <a href="/contactlist" className={`nav-item nav-link ${window.location.href.split('/')[3] == "contactlist" ? "active" : null}`}>
                      Contactos
                    </a>
                  </>
                  : null}
                {user1.role === "admin" ?
                  <>
                    <a href="/feriados" className={`nav-item nav-link ${window.location.href.split('/')[3] == "contactlist" ? "active" : null}`}>
                      Dias feriados
                    </a>
                  </>
                  : null}
                <a href="/profile" className={`nav-item nav-link ${window.location.href.split('/')[3] == "profile" ? "active" : null}`}>
                  Perfil
                </a>
                <a href="/logout" className={`nav-item nav-link ${window.location.href.split('/')[3] == "logout" ? "active" : null}`}>
                  Salir
                </a>
              </>
              :
              <>
                {/* <a href="/faq" className={`nav-item nav-link ${window.location.href.split('/')[3] == "faq" ? "active" : null}`}>
                  Preguntas frecuentes
                </a> */}
                <a onClick={() => pregunta()} className={`nav-item nav-link ${window.location.href.split('/')[3] == "register" ? "active" : null}`}>
                  Registrate
                </a>
                {/* <a href="/register" className={`nav-item nav-link ${window.location.href.split('/')[3] == "register" ? "active" : null}`}>
                  Registrate
                </a> */}
                <a href="/contact" className={`nav-item nav-link ${window.location.href.split('/')[3] == "contact" ? "active" : null}`}>
                  Contacto
                </a>
              </>
            }
            {/* <div className="nav-item dropdown">
              <a
                href="#"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                Pages
              </a>
              <div className="dropdown-menu m-0">
                <a href="price.html" className="dropdown-item">
                  Pricing Plan
                </a>
                <a href="team.html" className="dropdown-item">
                  Our Dentist
                </a>
                <a href="testimonial.html" className="dropdown-item">
                  Testimonial
                </a>
                <a href="appointment.html" className="dropdown-item">
                  Appointment
                </a>
              </div>
            </div> */}
          </div>
          {/* <button
            type="button"
            className="btn text-dark"
            data-bs-toggle="modal"
            data-bs-target="#searchModal"
          >
            <i className="fa fa-search" />
          </button> */}
          {isAuth ? null : <Login titulo="Iniciar sesión" />}
        </div>
      </nav>
    </>
  );
};

export default Header;
