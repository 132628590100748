import React, { useState, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Button, Modal } from "react-bootstrap";
import "../../index.css";
import CommentsList from "./List";

function BotComment(props) {
  const { isAuth } = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {isAuth ? (
        <div>
          <Button className="btn btn-primary" onClick={handleShow}>
            <i className="far fa-clipboard"></i>
          </Button>
          <Modal show={show} size="sm" onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                Comentarios sobre {props.first_name} {props.last_name}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CommentsList
                id={props.id}
                first_name={props.first_name}
                last_name={props.last_name}
              />
            </Modal.Body>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default BotComment;
