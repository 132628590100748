import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import Header from "../Layout/Header/Header";
import ReactLoading from 'react-loading'
import "../../index.css";

function Feriados() {
    let { isAuth, user1 } = useContext(AuthContext);
    let [dias, setDias] = useState([]);
    let [force, setForce] = useState(0)
    let [loading, setLoading] = useState(true)
    let [id, setId] = useState()

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API}feriados`, {
            headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
        })
            .then((data) => { setDias(data.data[0].fechas); setId(data.data[0]._id); setLoading(false) })
            .catch((err) => console.log(err));

        // eslint-disable-next-line
    }, []);

    function guardarCambios() {
        setLoading(true)
        axios.patch(`${process.env.REACT_APP_API}feriados/${user1.id}/${id}`,
            {
                fechas: dias,
            },
            {
                headers: { Authorization: `Bearer: ${localStorage.getItem("app_token")}`, },
            }
        )
            .then((data) => {
                Swal.fire({ icon: "success", title: "Guardado", text: "Se guardaron los cambios", });
                setLoading(false)
            })
            .catch((err) => {
                Swal.fire({ icon: "error", title: "Error", text: "No se pudieron guardar los cambios", });
                setLoading(false)
            });
    }

    return (
        <>
            {isAuth ? (
                user1.role === "admin" ? (
                    <>
                        <Header />
                        <br /><br />

                        <center>
                            <h1 className="margin">Dias Feriados</h1>
                            <br />
                        </center>

                        <center>
                            <div className="row">
                                <div className="col">
                                    <input type="date" onChange={(e) => { dias.push(e.target.value); setForce(force + 1) }} />

                                </div>

                                <div className="col">
                                    {dias.map((dia) => {
                                        return (
                                            <div>
                                                <h4>{dia} <Button variant="danger" onClick={() => { setDias(dias.filter((e) => e !== dia)) }}>X</Button></h4>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </center>

                        <center>
                            <Button onClick={() => guardarCambios()}>Guardar cambios</Button>
                        </center>

                        {loading ?
                            <center>
                                <div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div>
                            </center>
                            : null}
                    </>
                ) : undefined
            ) : undefined
            }
        </>
    );
}

export default Feriados;
