import React, { useState, useContext } from "react";
import { Container, Button, Form, Col, Row } from "react-bootstrap";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import Header from "../Layout/Header/Header";
import Swal from "sweetalert2";

import "../../index.css";

function Formulario(props) {
  const { user1, isAuth } = useContext(AuthContext);
  const [tel, setTel] = useState();
  const [domicilio, setDomicilio] = useState();
  const [localidad, setLocalidad] = useState();
  const [recomendadoPor, setRecomendadoPor] = useState();
  const [medicoCabecera, setMedicoCabecera] = useState();
  const [telMedicoCabecera, setTelMedicoCabecera] = useState();
  const [desayuno, setDesayuno] = useState();
  const [cepillasDesayuno, setCepillasDesayuno] = useState(false);
  const [mediaMañana, setMediaMañana] = useState();
  const [almuerzoComida, setAlmuerzoComida] = useState();
  const [almuerzoBebida, setAlmuerzoBebida] = useState();
  const [almuerzoPostre, setAlmuerzoPostre] = useState();
  const [merienda, setMerienda] = useState();
  const [cenaComida, setCenaComida] = useState();
  const [cenaBebida, setCenaBebida] = useState();
  const [cepillasCena, setCepillasCena] = useState(false);
  const [horaDormir, setHoraDormir] = useState();
  const [motivoConsulta, setMotivoConsulta] = useState();
  const [diabetes, setDiabetes] = useState(false);
  const [cardiacas, setCardiacas] = useState(false);
  const [renales, setRenales] = useState(false);
  const [hepaticas, setHepaticas] = useState(false);
  const [neurologicas, setNeurologicas] = useState(false);
  const [anemia, setAnemia] = useState(false);
  const [epilepsia, setEpilepsia] = useState(false);
  const [mareos, setMareos] = useState(false);
  const [desmayos, setDesmayos] = useState(false);
  const [alergias, setAlergias] = useState(false);
  const [otras, setOtras] = useState(false);
  const [cualesEnfermedades, setCualesEnfermedades] = useState();
  const [medicamento, setMedicamento] = useState(false);
  const [cualesMedicamentos, setCualesMedicamentos] = useState();
  const [medicamentoMal, setMedicamentoMal] = useState(false);
  const [cualesMal, setCualesMal] = useState();
  const [anesteciaLocal, setAnesteciaLocal] = useState(false);
  const [hemorragiasExtraccion, setHemorragiasExtraccion] = useState(false);
  const [medicacionPrevia, setMedicacionPrevia] = useState(false);
  const [fuma, setFuma] = useState(false);
  const [cantidadCigarrillos, setCantidadCigarrillos] = useState();
  const [embarazada, setEmbarazada] = useState(false);
  const [mesEmbarazo, setMesEmbarazo] = useState();
  const [medicoAutoriza, setMedicoAutoriza] = useState(false);
  const [comentarios, setComentarios] = useState();
  const [ninos, setNinos] = useState(false);
  const [nacioTermino, setNacioTermino] = useState(false);
  const [nacioPeso, setNacioPeso] = useState();
  const [amamanto, setAmamanto] = useState(false);
  const [mamadera, setMamadera] = useState(false);
  const [mamaderaAzucar, setMamaderaAzucar] = useState(false);
  const [edadCepillado, setEdadCepillado] = useState();
  const USERPATCH = `${process.env.REACT_APP_API}editusers/${user1.id}/${user1.id}`;

  const sendForm = () => {
    axios
      .patch(
        USERPATCH,
        {
          tel,
          domicilio,
          localidad,
          recomendadoPor,
          medicoCabecera,
          telMedicoCabecera,
          desayuno,
          cepillasDesayuno,
          mediaMañana,
          almuerzoComida,
          almuerzoBebida,
          almuerzoPostre,
          merienda,
          cenaComida,
          cenaBebida,
          cepillasCena,
          horaDormir,
          motivoConsulta,
          diabetes,
          cardiacas,
          renales,
          hepaticas,
          neurologicas,
          anemia,
          epilepsia,
          mareos,
          desmayos,
          alergias,
          otras,
          cualesEnfermedades,
          medicamento,
          cualesMedicamentos,
          medicamentoMal,
          cualesMal,
          anesteciaLocal,
          hemorragiasExtraccion,
          medicacionPrevia,
          fuma,
          cantidadCigarrillos,
          embarazada,
          mesEmbarazo,
          medicoAutoriza,
          comentarios,
          ninos,
          nacioTermino,
          nacioPeso,
          amamanto,
          mamadera,
          mamaderaAzucar,
          edadCepillado,
          formulario: false,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Se edito con exito",
          confirmButtonText: `Ok`,
          timer: 1000,
          timerProgressBar: true,
          allowEscapeKey: true,
        }).then(() => {
          window.location.href = "/";
        });
      })
      .catch((error) => {
        let message = error.response.data.message;
        console.log(error.response);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Lo sentimos esta acción no se pudo completar " + message,
          allowEscapeKey: true,
        });
      });
  };

  return (
    <>
      {isAuth ? (
        user1.role === "user" ? (
          <>
            <Header />
            <Container className="themed-container" fluid={true}>
              <h1 className="mb-4 reg">Ya estas mas cerca de tu turno</h1>
              <Form className="container form-regis">
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Domicilio</Form.Label>
                      <Form.Control
                        value={domicilio}
                        onChange={(e) => setDomicilio(e.target.value)}
                        type="text"
                        name="domicilio"
                        id="exampleDomicilio"
                        placeholder="Domicilio"
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Localidad</Form.Label>
                      <Form.Control
                        value={localidad}
                        onChange={(e) => setLocalidad(e.target.value)}
                        type="text"
                        name="localidad"
                        id="exampleLocalidad"
                        placeholder="Localidad"
                      />
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group>
                      <Form.Label>Teléfono</Form.Label>
                      <Form.Control
                        value={tel}
                        onChange={(e) => setTel(e.target.value)}
                        type="number"
                        name="tel"
                        id="exampleTel"
                        placeholder="teléfono"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>

                  <Col>
                    <Form.Group>
                      <Form.Label>Recomendado por</Form.Label>
                      <Form.Control
                        value={recomendadoPor}
                        onChange={(e) => setRecomendadoPor(e.target.value)}
                        type="text"
                        name="recomendadoPor"
                        id="exampleRecomendadoPor"
                        placeholder="¿Quién te recomendo consultorios Odin?"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Médico Cabecera</Form.Label>
                      <Form.Control
                        value={medicoCabecera}
                        onChange={(e) => setMedicoCabecera(e.target.value)}
                        type="text"
                        name="medicoCabecera"
                        id="exampleMedicoCabecera"
                        placeholder="¿Quién es tu médico de cabecera?"
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Teléfono médico cabecera</Form.Label>
                      <Form.Control
                        value={telMedicoCabecera}
                        onChange={(e) => setTelMedicoCabecera(e.target.value)}
                        type="number"
                        name="telMedicoCabecera"
                        id="exampletelMedicoCabecera"
                        placeholder="¿Cual es su teléfono?"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Desayuno</Form.Label>
                      <Form.Control
                        value={desayuno}
                        onChange={(e) => setDesayuno(e.target.value)}
                        type="text"
                        name="desayuno"
                        id="exampleDesayuno"
                        placeholder="¿Que desayunas?"
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>¿Te cepillas después?</Form.Label>
                      <Form.Check
                        value={cepillasDesayuno}
                        onChange={() =>
                          cepillasDesayuno
                            ? setCepillasDesayuno(false)
                            : setCepillasDesayuno(true)
                        }
                        type="switch"
                        label={cepillasDesayuno ? "Sí" : "No"}
                        id="exampleCepillasDesayuno"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Media Mañana</Form.Label>
                      <Form.Control
                        value={mediaMañana}
                        onChange={(e) => setMediaMañana(e.target.value)}
                        type="text"
                        name="mediaMañana"
                        id="exampleMediaMañana"
                        placeholder="MediaMañana"
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Almuerzo</Form.Label>
                      <Form.Control
                        value={almuerzoComida}
                        onChange={(e) => setAlmuerzoComida(e.target.value)}
                        type="text"
                        name="almuerzoComida"
                        id="exampleAlmuerzoComida"
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Bebe</Form.Label>
                      <Form.Control
                        value={almuerzoBebida}
                        onChange={(e) => setAlmuerzoBebida(e.target.value)}
                        type="text"
                        name="almuerzoBebida"
                        id="exampleAlmuerzoBebida"
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Postre</Form.Label>
                      <Form.Control
                        value={almuerzoPostre}
                        onChange={(e) => setAlmuerzoPostre(e.target.value)}
                        type="text"
                        name="almuerzoPostre"
                        id="exampleAlmuerzoPostre"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Merienda</Form.Label>
                      <Form.Control
                        value={merienda}
                        onChange={(e) => setMerienda(e.target.value)}
                        type="text"
                        name="merienda"
                        id="exampleMerienda"
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Cena</Form.Label>
                      <Form.Control
                        value={cenaComida}
                        onChange={(e) => setCenaComida(e.target.value)}
                        type="text"
                        name="cenaComida"
                        id="exampleCenaComida"
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Bebida</Form.Label>
                      <Form.Control
                        value={cenaBebida}
                        onChange={(e) => setCenaBebida(e.target.value)}
                        type="text"
                        name="cenaBebida"
                        id="exampleCenaBebida"
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>¿Te cepillas después?</Form.Label>
                      <Form.Check
                        value={cepillasCena}
                        onChange={() =>
                          cepillasCena
                            ? setCepillasCena(false)
                            : setCepillasCena(true)
                        }
                        type="switch"
                        name="cepillasCena"
                        label={cepillasCena ? "Sí" : "No"}
                        id="exampleCepillasCena"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>¿A qué hora te duermes?</Form.Label>
                      <Form.Control
                        value={horaDormir}
                        onChange={(e) => setHoraDormir(e.target.value)}
                        type="text"
                        name="horaDormir"
                        id="exampleHoraDormir"
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>¿Motivo de su consulta?</Form.Label>
                      <Form.Control
                        value={motivoConsulta}
                        onChange={(e) => setMotivoConsulta(e.target.value)}
                        type="text"
                        name="motivoConsulta"
                        id="exampleMotivoConsulta"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>¿Enfermedades Cardíacas?</Form.Label>
                      <Form.Check
                        value={cardiacas}
                        onChange={() =>
                          cardiacas ? setCardiacas(false) : setCardiacas(true)
                        }
                        type="switch"
                        name="cardiacas"
                        id="exampleCardiacas"
                        label={cardiacas ? "Sí" : "No"}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>¿Diabetes?</Form.Label>
                      <Form.Check
                        value={diabetes}
                        onChange={() =>
                          diabetes ? setDiabetes(false) : setDiabetes(true)
                        }
                        type="switch"
                        name="diabetes"
                        id="exampleDiabetes"
                        label={diabetes ? "Sí" : "No"}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>¿Enfermedades Renales?</Form.Label>
                      <Form.Check
                        value={renales}
                        onChange={() =>
                          renales ? setRenales(false) : setRenales(true)
                        }
                        type="switch"
                        name="renales"
                        id="exampleRenales"
                        label={renales ? "Sí" : "No"}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>¿Anemia?</Form.Label>
                      <Form.Check
                        value={anemia}
                        onChange={() => (anemia ? setAnemia(false) : setAnemia(true))}
                        type="switch"
                        name="anemia"
                        id="exampleAnemia"
                        label={anemia ? "Sí" : "No"}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>¿Enfermedades Hepáticas?</Form.Label>
                      <Form.Check
                        value={hepaticas}
                        onChange={() =>
                          hepaticas ? setHepaticas(false) : setHepaticas(true)
                        }
                        type="switch"
                        name="hepaticas"
                        id="exampleHepaticas"
                        label={hepaticas ? "Sí" : "No"}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>¿Epilepsia?</Form.Label>
                      <Form.Check
                        value={epilepsia}
                        onChange={() =>
                          epilepsia ? setEpilepsia(false) : setEpilepsia(true)
                        }
                        type="switch"
                        name="epilepsia"
                        id="exampleEpilepsia"
                        label={epilepsia ? "Sí" : "No"}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>¿Enfermedades Neurológicas?</Form.Label>
                      <Form.Check
                        value={neurologicas}
                        onChange={() =>
                          neurologicas
                            ? setNeurologicas(false)
                            : setNeurologicas(true)
                        }
                        type="switch"
                        name="neurologicas"
                        id="exampleNeurologicas"
                        label={neurologicas ? "Sí" : "No"}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>¿Mareos?</Form.Label>
                      <Form.Check
                        value={mareos}
                        onChange={() => (mareos ? setMareos(false) : setMareos(true))}
                        type="switch"
                        name="mareos"
                        id="exampleMareos"
                        label={mareos ? "Sí" : "No"}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>¿Desmayos?</Form.Label>
                      <Form.Check
                        value={desmayos}
                        onChange={() =>
                          desmayos ? setDesmayos(false) : setDesmayos(true)
                        }
                        type="switch"
                        name="desmayos"
                        id="exampleDesmayos"
                        label={desmayos ? "Sí" : "No"}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>¿Alergías?</Form.Label>
                      <Form.Check
                        value={alergias}
                        onChange={(e) =>
                          alergias ? setAlergias(false) : setAlergias(true)
                        }
                        type="switch"
                        name="alergias"
                        id="exampleAlergias"
                        label={alergias ? "Sí" : "No"}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>¿Otras?</Form.Label>
                          <Form.Check
                            value={otras}
                            onChange={(e) =>
                              otras ? setOtras(false) : setOtras(true)
                            }
                            type="switch"
                            name="otras"
                            id="exampleOtras"
                            label={otras ? "Sí" : "No"}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        {otras ? (
                          <Form.Group>
                            <Form.Label>¿Cual?</Form.Label>
                            <Form.Control
                              value={cualesEnfermedades}
                              onChange={(e) => setCualesEnfermedades(e.target.value)}
                              type="text"
                              name="cualesEnfermedades"
                              id="exampleCualesEnfermedades"
                            />
                          </Form.Group>
                        ) : null}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>¿Toma algún medicamento?</Form.Label>
                      <Form.Check
                        value={medicamento}
                        onChange={() =>
                          medicamento ? setMedicamento(false) : setMedicamento(true)
                        }
                        type="switch"
                        name="medicamento"
                        id="exampleMedicamento"
                        label={medicamento ? "Sí" : "No"}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    {medicamento ? (
                      <Form.Group>
                        <Form.Label>¿Cuál?</Form.Label>
                        <Form.Control
                          value={cualesMedicamentos}
                          onChange={(e) => setCualesMedicamentos(e.target.value)}
                          type="text"
                          name="cualesMedicamentos"
                          id="exampleCualesMedicamentos"
                        />
                      </Form.Group>
                    ) : null}
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>¿Algún medicamento le hace mal?</Form.Label>
                      <Form.Check
                        value={medicamentoMal}
                        onChange={() =>
                          medicamentoMal
                            ? setMedicamentoMal(false)
                            : setMedicamentoMal(true)
                        }
                        type="switch"
                        name="medicamentoMal"
                        id="exampleMedicamentoMal"
                        label={medicamentoMal ? "Sí" : "No"}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    {medicamentoMal ? (
                      <Form.Group>
                        <Form.Label>¿Cuál?</Form.Label>
                        <Form.Control
                          value={cualesMal}
                          onChange={(e) => setCualesMal(e.target.value)}
                          type="text"
                          name="cualesMal"
                          id="exampleCualesMal"
                        />
                      </Form.Group>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>¿Recibió anestecia local bucal?</Form.Label>
                      <Form.Check
                        value={anesteciaLocal}
                        onChange={() =>
                          anesteciaLocal
                            ? setAnesteciaLocal(false)
                            : setAnesteciaLocal(true)
                        }
                        type="switch"
                        name="anesteciaLocal"
                        id="exampleAnesteciaLocal"
                        label={anesteciaLocal ? "Sí" : "No"}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>¿Hemorragias post extracción?</Form.Label>
                      <Form.Check
                        value={hemorragiasExtraccion}
                        onChange={() =>
                          hemorragiasExtraccion
                            ? setHemorragiasExtraccion(false)
                            : setHemorragiasExtraccion(true)
                        }
                        type="switch"
                        name="hemorragiasExtraccion"
                        id="exampleHemorragiasExtraccion"
                        label={hemorragiasExtraccion ? "Sí" : "No"}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        ¿Necesita medicación previa al tratamiento?
                      </Form.Label>
                      <Form.Check
                        value={medicacionPrevia}
                        onChange={() =>
                          medicacionPrevia
                            ? setMedicacionPrevia(false)
                            : setMedicacionPrevia(true)
                        }
                        type="switch"
                        name="medicacionPrevia"
                        id="exampleMedicacionPrevia"
                        label={medicacionPrevia ? "Sí" : "No"}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>¿Su médico autoriza el tratamiento?</Form.Label>
                      <Form.Check
                        value={medicoAutoriza}
                        onChange={() =>
                          medicoAutoriza
                            ? setMedicoAutoriza(false)
                            : setMedicoAutoriza(true)
                        }
                        type="switch"
                        name="medicoAutoriza"
                        id="exampleMedicoAutoriza"
                        label={medicoAutoriza ? "Sí" : "No"}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>¿Fuma?</Form.Label>
                      <Form.Check
                        value={fuma}
                        onChange={() => (fuma ? setFuma(false) : setFuma(true))}
                        type="switch"
                        name="fuma"
                        id="exampleFuma"
                        label={fuma ? "Sí" : "No"}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    {fuma ? (
                      <Form.Group>
                        <Form.Label>¿Cuantos cigarrillos por día?</Form.Label>
                        <Form.Control
                          value={cantidadCigarrillos}
                          onChange={(e) => setCantidadCigarrillos(e.target.value)}
                          type="number"
                          name="cantidadCigarrillos"
                          id="exampleCantidadCigarrillos"
                        />
                      </Form.Group>
                    ) : null}
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>¿Está embarazada?</Form.Label>
                      <Form.Check
                        value={embarazada}
                        onChange={() =>
                          embarazada ? setEmbarazada(false) : setEmbarazada(true)
                        }
                        type="switch"
                        name="embarazada"
                        id="exampleEmbarazada"
                        label={embarazada ? "Sí" : "No"}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    {embarazada ? (
                      <Form.Group>
                        <Form.Label>¿En qué mes?</Form.Label>
                        <Form.Control
                          value={mesEmbarazo}
                          onChange={(e) => setMesEmbarazo(e.target.value)}
                          type="number"
                          name="mesEmbarazo"
                          id="exampleMesEmbarazo"
                        />
                      </Form.Group>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm="6">
                    <Form.Group>
                      <Form.Label>
                        Comentarios adicionales que nos pueden ser útiles
                      </Form.Label>
                      <Form.Control
                        value={comentarios}
                        onChange={(e) => setComentarios(e.target.value)}
                        type="text"
                        name="comentarios"
                        id="exampleComentarios"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>¿El paciente es niñ@?</Form.Label>
                      <Form.Check
                        value={ninos}
                        onChange={(e) => (ninos ? setNinos(false) : setNinos(true))}
                        type="switch"
                        name="ninos"
                        id="exampleNinos"
                        label={ninos ? "Sí" : "No"}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {ninos ? (
                  <Row>
                    <Col sm="4">
                      <Form.Group>
                        <Form.Label>¿Nació en termino?</Form.Label>
                        <Form.Check
                          value={nacioTermino}
                          onChange={() =>
                            nacioTermino
                              ? setNacioTermino(false)
                              : setNacioTermino(true)
                          }
                          type="switch"
                          name="nacioTermino"
                          id="exampleNacioTermino"
                          label={nacioTermino ? "Sí" : "No"}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group>
                        <Form.Label>¿Con que peso nacio?</Form.Label>
                        <Form.Control
                          value={nacioPeso}
                          onChange={(e) => setNacioPeso(e.target.value)}
                          type="number"
                          name="nacioPeso"
                          id="exampleNacioPeso"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group>
                        <Form.Label>¿Se amamantó?</Form.Label>
                        <Form.Check
                          value={amamanto}
                          onChange={() =>
                            amamanto ? setAmamanto(false) : setAmamanto(true)
                          }
                          type="switch"
                          name="amamanto"
                          id="exampleAmamanto"
                          label={amamanto ? "Sí" : "No"}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group>
                        <Form.Label>¿Tomó mamadera?</Form.Label>
                        <Form.Check
                          value={mamadera}
                          onChange={() =>
                            mamadera ? setMamadera(false) : setMamadera(true)
                          }
                          type="switch"
                          name="mamadera"
                          id="exampleMamadera"
                          label={mamadera ? "Sí" : "No"}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group>
                        <Form.Label>¿Con azúcar?</Form.Label>
                        <Form.Check
                          value={mamaderaAzucar}
                          onChange={() =>
                            mamaderaAzucar
                              ? setMamaderaAzucar(false)
                              : setMamaderaAzucar(true)
                          }
                          type="switch"
                          name="mamaderaAzucar"
                          id="exampleMamaderaAzucar"
                          label={mamaderaAzucar ? "Sí" : "No"}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group>
                        <Form.Label>
                          ¿A que edad comenzaron a cepillarse los dientes?
                        </Form.Label>
                        <Form.Control
                          value={edadCepillado}
                          onChange={(e) => setEdadCepillado(e.target.value)}
                          type="number"
                          name="edadCepillado"
                          id="exampleEdadCepillado"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                ) : null}
              </Form>
              <Button
                variant="info"
                type="submit"
                onClick={() => {
                  sendForm();
                }}
              >
                Enviar
              </Button>
            </Container>
            {/* <Footer /> */}
          </>
        ) : undefined
      ) : undefined}
    </>
  );
}

export default Formulario;
