/* eslint-disable */
import React, { useState, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Form, Button, Modal, Col, Row } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import "../../index.css";
import "./Apointment.css"
import dayjs from 'dayjs'
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
registerLocale('es', es)

function SobreTurno(props) {
  const { user1, isAuth } = useContext(AuthContext);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState("Hora");
  const [note, setNote] = useState("Escribí aca algún comentario al sobre turno");
  const doctor = user1.id
  const doctorName = props.nombre
  const doctorLname = props.apellido
  const [startDate, setStartDate] = useState(null);
  const SCHUDULEPOST = `${process.env.REACT_APP_API}schedule/${user1.id}`;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let jsonSend = {
    date,
    profesional: doctor,
    note,
    time,
    type: true,
    user: props.id,
  }

  const diaSeleccionado = (selectedDay) => {
    setTime(`${String(dayjs(selectedDay).$H).padStart(2, '0')}:${String(dayjs(selectedDay).$m).padStart(2, '0')}`)
    setStartDate(selectedDay)
    setDate(dayjs(selectedDay).toISOString())
  };

  const saveDate = () => {
    if (date === "Fecha" || time === "Hora") {
      return Swal.fire({
        allowEscapeKey: true,
        icon: "info",
        title: "Informacion incompleta",
        text: "Favor de llenar todos los campos",
        confirmButtonText: `Ok`,
        timerProgressBar: true,
      });
    }
    Swal.fire({
      title: `El sobre turno para ${doctorName} ${doctorLname}, para el ${dayjs(date).format("DD/MM/YYYY")} a las ${time} hrs`,
      text: "Es correcta la informacion ?",
      icon: "question",
      showCancelButton: true,
      reverseButtons: true,
      allowEscapeKey: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Agendar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(SCHUDULEPOST, jsonSend, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
        )
          .then(() => {
            Swal.fire({
              allowEscapeKey: true,
              icon: "success",
              title: "Listo! el sobre turno esta agendado",
              confirmButtonText: `Ok`,
              timer: 1000,
              timerProgressBar: true,
            }).then(() => {
              window.location.reload();
            });
          })
          .catch((error) => {
            let message = error.response.data.message;
            Swal.fire({
              allowEscapeKey: true,
              icon: "error",
              title: "Oops...",
              text: "Lo sentimos esta acción no se pudo completar " + message,
            });
            console.log(error);
          });
      }
    });
  };

  return (
    <>
      {isAuth ? (
        user1.role === "doctor" || user1.role === "secre" ? (
          <div>
            <Button variant="primary" onClick={handleShow}><i className="far fa-calendar-plus"></i></Button>
            <Modal show={show} size="md" onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>{`${props.nombre} ${props.apellido}`}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Group>
                        <DatePicker includeDateIntervals={[{ start: new Date(), end: new Date(dayjs().add(1, 'year')) },]} calendarStartDay={0} locale="es" todayButton="Hoy" placeholderText="Hora y Fecha" shouldCloseOnSelect={false} selected={startDate} timeInputLabel="Hora:" dateFormat="MM/dd/yyyy h:mm aa" showTimeInput onChange={(date) => diaSeleccionado(date)} />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <textarea className="note" placeholder={note} rows="3" onChange={(e) => { setNote(e.target.value) }} />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button type="submit" onClick={() => { saveDate(); }} className="btn btn-info">Siguiente</Button>
              </Modal.Footer>
            </Modal>
          </div>
        ) : undefined
      ) : undefined}
    </>
  );
}

export default SobreTurno;
