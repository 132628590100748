import React, { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import Swal from "sweetalert2";
// import dayjs from "dayjs";
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

function DeleteSchedule(props) {
  const { isAuth, user1 } = useContext(AuthContext);
  const URLDELETE = `${process.env.REACT_APP_API}schedule/${user1.id}/${props.id}`;
  const URL_GET_USER = `${process.env.REACT_APP_API}user/${props.userId}`;
  const URL_EDIT_USER = `${process.env.REACT_APP_API}editusers/${user1.id}/${props.userId}`;
  // let tarde = dayjs().$d >= dayjs(`${props.datee.split("T")[0]} ${props.timee}`).subtract(1, 'day').$d

  const Borrar = () => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "Esta acción no se puede revertir",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      allowEscapeKey: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#25a1b7",
      confirmButtonText: "Cancelar",
      cancelButtonText: "Conservar",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.get(URL_GET_USER, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
          .then((resDB) => {
            axios.patch(URL_EDIT_USER, {
              cancelaciones: resDB.data.cancelaciones + 1,
              listaCancelaciones: resDB.data.listaCancelaciones.concat([`${props.datee.split("T")[0]} ${props.timee}`]),
              pacienteExistente: props.primeraVez ? false : true,
              primeraCita: props.primeraVez ? false : true,
            }, {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            })
              .then(() => {
                axios
                  .delete(URLDELETE, {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                    },
                  })
                  .then(() => {
                    Swal.fire({
                      icon: "success",
                      title: "Se elimino con exito",
                      confirmButtonText: `Ok`,
                      timer: 1000,
                      timerProgressBar: true,
                      allowEscapeKey: true,
                    }).then(() => {
                      window.location.reload();
                    });
                  })
                  .catch((error) => {
                    let message = error.response.data.message
                    Swal.fire({
                      allowEscapeKey: true,
                      icon: "error",
                      title: "Oops...",
                      text: "Lo sentimos esta acción no se pudo completar " + message,
                    });
                  });
              })
              .catch((error) => {
                console.log(error)
              })
          })
          .catch((error) => {
            console.log(error)
          })

      }
    });
  };

  return (
    <>
      {isAuth ? (
        <OverlayTrigger
          placement="top" // La posición del tooltip (puede ser "top", "bottom", "left", "right")
          overlay={<Tooltip id="button-tooltip">Eliminar turno</Tooltip>}
        >
          <Button onClick={Borrar} className="btn btn-danger">
            <i className="far fa-trash-alt"></i>
          </Button >
        </OverlayTrigger>
      ) : undefined}
    </>
  );
}

export default DeleteSchedule;
