/* eslint-disable */
import React, { useState, useContext, useLayoutEffect, useEffect } from "react";
import { Form, Button, Modal, Col, Row, Dropdown, DropdownButton } from "react-bootstrap";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import Swal from "sweetalert2";
import EditPassword from "../Edit/EditPasword";
import "../../index.css";
import dayjs from "dayjs";

function EditProfile(props) {
  let { user1, isAuth } = useContext(AuthContext);
  let [nombre, setNombre] = useState(props.data.nombre);
  let [apellido, setApellido] = useState(props.data.apellido);
  let [celular, setCelular] = useState(props.data.celular);
  let [nacimiento, setNacimiento] = useState(props.data.nacimiento);
  let [especialidad, setEspecialidad] = useState(props.data.especialidad);
  let [email, setEmail] = useState(undefined);
  let USERPATCH = `${process.env.REACT_APP_API}${props.route}/${user1.id}/${props.data._id}`;
  let USRGET = `${process.env.REACT_APP_API}user/${user1.id}`;
  let FILPOST = `${process.env.REACT_APP_API}upprofile/`;
  let [show, setShow] = useState(false);
  let handleClose = () => setShow(false);
  let handleShow = () => setShow(true);
  let [file, setFile] = useState();
  let [obras, setObras] = useState([]);
  let [obrasEscogidas, setObrasEscogidas] = useState([]);
  let [especialidades, setEspecialidades] = useState([]);
  let [especialidadesEscogidas, setEspecialidadesEscogidas] = useState([]);
  let [a, setA] = useState([]);
  let [obraSocial, setObraSocial] = useState(props.data.obraSocial);
  let titleedbot = `${obraSocial}`
  let [limiteCitasPaciente, setLimiteCitasPaciente] = useState(props.data.limiteCitasPaciente);
  let [limiteEdad, setLimiteEdad] = useState(props.data.limiteEdad);
  let [limiteEdadInferior, setLimiteEdadInferior] = useState();

  useLayoutEffect(() => {
    axios.get(`${process.env.REACT_APP_API}obrassociales/`)
      .then((resDB) => setObras(resDB.data))
      .catch((error) => console.log(error))

    axios.get(`${process.env.REACT_APP_API}especialidades/`)
      .then((resDB) => setEspecialidades(resDB.data))
      .catch((error) => console.log(error))

    axios.get(USRGET, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
      .then((data) => (
        setObrasEscogidas(data.data.obraSocialAtendida),
        setEspecialidadesEscogidas(data.data.especialidad)
      ))
      .catch((err) => console.log(err));
  }, [])

  useEffect(() => {
    setObraSocial(props.data.obraSocial)
  }, [props])

  function obraEscogida(obra) {
    if (obra != undefined) {
      obrasEscogidas.some((item) => item.nombre == obra) ? setObrasEscogidas(obrasEscogidas.filter(item => item.nombre != obra)) : obrasEscogidas.push({ nombre: obra });
      setA(Math.random())
    }
  }

  function especialidadEscogida(especialidad) {
    if (especialidad != undefined) {
      especialidadesEscogidas.includes(especialidad) ? setEspecialidadesEscogidas(especialidadesEscogidas.filter(e => e != especialidad)) : especialidadesEscogidas.push(especialidad);
      setA(Math.random())
    }
  }

  let editUser = () => {
    Swal.fire({
      title: `Tu informacion sera editada`,
      icon: "warning",
      showCancelButton: true,
      allowEscapeKey: true,
      reverseButtons: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Confirmar cambios",
    }).then((result) => {
      if (result.isConfirmed) {
        if (file == null) {
          axios
            .patch(
              USERPATCH,
              {
                nombre,
                apellido,
                celular,
                especialidad: especialidadesEscogidas,
                email,
                obraSocialAtendida: obrasEscogidas,
                obraSocial,
                limiteCitasPaciente,
                limiteEdad,
                limiteEdadInferior,
                nacimiento
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            .then(() => {
              Swal.fire({
                icon: "success",
                title: "Se edito con exito",
                confirmButtonText: `Ok`,
                timer: 1000,
                timerProgressBar: true,
                allowEscapeKey: true,
              }).then(() => {
                window.location.reload();
              });
            })
            .catch((error) => {
              let message = error.response.data.message;
              console.log(error.response);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Lo sentimos esta acción no se pudo completar " + message,
                allowEscapeKey: true,
              });
            });
        } else {
          axios
            .patch(
              USERPATCH,
              {
                nombre,
                apellido,
                celular,
                especialidad,
                email,
                limiteCitasPaciente,
                limiteEdad,
                limiteEdadInferior
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            .then((data) => {
              const formData = new FormData();
              formData.append("file", file);
              formData.append("user", data.data._id);
              axios
                .post(FILPOST, formData)
                .then((res) => {
                  console.log(res);
                })
                .catch((error) => {
                  console.log(error);
                });

              Swal.fire({
                icon: "success",
                title: "Se edito con exito",
                confirmButtonText: `Ok`,
                timer: 1000,
                timerProgressBar: true,
                allowEscapeKey: true,
              }).then(() => {
                window.location.reload();
              });
            })
            .catch((error) => {
              let message = error.response.data.message;
              console.log(error.response);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Lo sentimos esta acción no se pudo completar " + message,
                allowEscapeKey: true,
              });
            });
        }
      }
    });
  };

  return (
    <>
      {isAuth ? (
        <div>
          <Button variant="info" onClick={handleShow}>
            {<i className="far fa-edit"></i>} Edita tu informacion
          </Button>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Edita tus datos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Nombre</Form.Label>
                      <Form.Control
                        placeholder={props.data.nombre}
                        onChange={(e) => setNombre(e.target.value)}
                        type="text"
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Apellido</Form.Label>
                      <Form.Control
                        placeholder={props.data.apellido}
                        onChange={(e) => setApellido(e.target.value)}
                        type="text"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {
                  user1.role == "user" ? (
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Fecha de nacimiento {props.data.nacimiento ? dayjs(String(props.data.nacimiento.split("T")[0])).format('DD/MM/YYYY') : null}</Form.Label>
                          <Form.Control
                            onChange={(e) => setNacimiento(e.target.value)}
                            // placeholder={props.data.nacimiento ? dayjs(String(props.data.nacimiento.split("T")[0])).format('DD/MM/YYYY'): null}
                            type="date"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  ) : null
                }
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Celular</Form.Label>
                      <Form.Control
                        value={celular}
                        placeholder={props.data.celular}
                        onChange={(e) => setCelular(e.target.value)}
                        type="text"
                        id="celular"
                        name="inputcelular"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        placeholder={props.data.email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {user1.role == "user" ? (
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Cobertura </Form.Label>
                        <DropdownButton variant="outline-info" title={titleedbot}>
                          {obras.map((obra, i) => (
                            <Dropdown.Item
                              onClick={() => {
                                setObraSocial(obra.nombre);
                              }}
                              key={i}
                            >
                              <h4 className="alineacion">
                                {obra.nombre}
                              </h4>
                            </Dropdown.Item>
                          ))}
                        </DropdownButton>
                      </Form.Group>
                    </Col>
                  </Row>
                ) : user1.role == "doctor" ? (
                  <>
                    <Row>
                      <Col>
                        <Form.Label>Limite de edad de cada paciente</Form.Label>
                        <Row>
                          <Col>
                            <Form.Group>
                              <Form.Label>Inferior</Form.Label>
                              <Form.Control
                                style={{ "maxWidth": "1vw" }}
                                placeholder={props.data.limiteEdadInferior}
                                onChange={(e) => setLimiteEdadInferior(e.target.value)}
                                type="number"
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group>
                              <Form.Label>Superior</Form.Label>
                              <Form.Control
                                style={{ "maxWidth": "1vw" }}
                                placeholder={props.data.limiteEdad}
                                onChange={(e) => setLimiteEdad(e.target.value)}
                                type="number"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Limite semanal de turnos por paciente</Form.Label>
                          <Form.Control
                            style={{ "maxWidth": "1vw" }}
                            placeholder={props.data.limiteCitasPaciente}
                            onChange={(e) => setLimiteCitasPaciente(e.target.value)}
                            type="number"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Obras Sociales</Form.Label>
                          <br />
                          {obras.map((item, i) => (
                            <div style={{ "marginTop": "1vh" }} className="d-flex justify-content-evenly">
                              <Button size="sm" style={{ "minWidth": "10vw" }} className="margdin" value={item.nombre} onClick={(event) => obraEscogida(event.target.value, i)} variant={obrasEscogidas.some((x) => x.nombre == item.nombre) ? "info" : "outline-info"}>{item.nombre}</Button>

                              {obrasEscogidas.some((x) => x.nombre == item.nombre) ?
                                <>
                                  <Form.Label>Limite semanal de turnos {item.nombre == "Particular" ? null : "de"} {item.nombre}{item.nombre == "Particular" ? "es" : null}</Form.Label>
                                  <input
                                    style={{ "maxWidth": "1vw", "maxHeight": "4vh" }}
                                    placeholder={obrasEscogidas[obrasEscogidas.findIndex(x => x.nombre === item.nombre)].limite}
                                    onChange={(e) => { obrasEscogidas[obrasEscogidas.findIndex(x => x.nombre === item.nombre)].limite = e.target.value; setA(Math.random()) }}
                                    type="number"
                                  />
                                </>
                                : null
                              }
                            </div>
                          ))}
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>Especialidades</Form.Label>
                          <br />
                          {especialidades.map((item, i) => (
                            <Button size="sm" style={{ "minWidth": "8vw" }} className="margin" value={item.nombre} onClick={(event) => especialidadEscogida(event.target.value)} variant={especialidadesEscogidas.includes(item.nombre) ? "info" : "outline-info"}>{item.nombre}</Button>
                          ))}
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                ) : null}
                {/* <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Foto</Form.Label>
                      <Form.File>
                        <Form.File.Input
                          onChange={(e) => {
                            setFile(e.target.files[0]);
                          }}
                        />
                      </Form.File>
                    </Form.Group>
                  </Col>
                </Row> */}
                <br />
                <Row>
                  <Col>
                    <EditPassword id={props.data.id} />
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button type="submit" onClick={() => { editUser(); }} className="btn btn-info">Guardar cambios</Button>
            </Modal.Footer>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default EditProfile;
