/* eslint-disable */
import React, { useState, useContext, useLayoutEffect } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import { Container, Button, Form } from "react-bootstrap";
import Header from "../Layout/Header/Header";
import Footer from "../Layout/Footer/Footer";
import axios from "axios";
import Swal from "sweetalert2";
import "../../index.css";

function SignupSecre() {
  const { user1, isAuth } = useContext(AuthContext);
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [dni, setDni] = useState("");
  const [nacimiento, setNacimiento] = useState();
  const [celular, setCelular] = useState("54911");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const history = useHistory();
  const [file, setFile] = useState();

  const handleForm = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      Swal.fire({
        icon: "error",
        title: "Verifica tu contraseña",
        allowEscapeKey: true,
      });
    } else {
      const jsonSend = {
        role: "secre",
        nombre: nombre,
        apellido: apellido,
        email,
        password: dni,
        nacimiento,
        dni,
        celular,
      };

      const SIGNUP_URL = `${process.env.REACT_APP_API}signupdoctor/${user1.id}`;
      const FILPOST = `${process.env.REACT_APP_API}upprofile/`;

      try {
        await axios.post(SIGNUP_URL, jsonSend).then((data) => {
          if (file) {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("user", data.data._id);
            axios
              .post(FILPOST, formData)
              .then((res) => {
                console.log(res);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
        setNombre("");
        setApellido("");
        setEmail("");
        setPassword("");
        setNacimiento(""), 
        setDni("");
        setCelular("");
        setFile();
        Swal.fire({
          icon: "success",
          title: "Usuario con perfil de secretaria creado con exito",
          text: "Inicia sesión",
          timer: 1000,
          timerProgressBar: true,
          allowEscapeKey: true,
        }).then(history.push("/"));
      } catch (error) {
        console.log(error);
        let message = error.response.data.message;
        Swal.fire({
          icon: "error",
          title: "No se puede crear secretaria ",
          text: "Algo salio mal, " + message,
          timer: 3000,
          timerProgressBar: true,
          allowEscapeKey: true,
        });
      }
    }
  };
  return (
    <>
      {isAuth ? (
        user1.role === "admin" ? (
          <>
            <Header />
            <Container className="themed-container" fluid={true}>
              <h1 className="mb-4 reg">Registra una nueva secretaria</h1>
              <Form className="container form-regis" onSubmit={handleForm}>
                <Form.Group>
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control
                    value={nombre}
                    onChange={(e) => setNombre(e.target.value)}
                    type="text"
                    placeholder="Escribe tu nombre"
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Apellido</Form.Label>
                  <Form.Control
                    value={apellido}
                    onChange={(e) => setApellido(e.target.value)}
                    type="text"
                    placeholder="Escribe tu apellido"
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Dni</Form.Label>
                  <Form.Control
                    value={dni}
                    onChange={(e) => setDni(e.target.value)}
                    type="text"
                    placeholder="Dni"
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Fecha de nacimiento</Form.Label>
                  <Form.Control
                    value={nacimiento}
                    onChange={(e) => setNacimiento(e.target.value)}
                    type="date"
                    name="nacimiento"
                    id="exampleNacimiento"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder="Correo electronico"
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Celular</Form.Label>
                  <Form.Control
                    value={celular}
                    onChange={(e) => setCelular(e.target.value)}
                    type="number"
                    placeholder="Teléfono"
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Foto</Form.Label>
                  <Form.File>
                   <Form.File.Input
                     onChange={(e) => {
                    setFile(e.target.files[0]);
                   }}
                  />
                 </Form.File>
                </Form.Group>
                <Button variant="info" type="submit">
                  Enviar
                </Button>
              </Form>
            </Container>
            {/* <Footer /> */}
          </>
        ) : undefined
      ) : undefined}
    </>
  );
}

export default SignupSecre;
