/* eslint-disable */
import React, { useEffect, useState, useContext, useLayoutEffect } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Container, ListGroup, Row, Col } from "react-bootstrap";
import axios from "axios";
import Header from "../Layout/Header/Header";
import Footer from "../Layout/Footer/Footer";
import EditProfile from "./EditProfile";
import "../../index.css";
import dayjs from "dayjs";

function Profile() {
  const { isAuth, user1 } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [photo, setPhoto] = useState({});
  const URL_GET_INFO = `${process.env.REACT_APP_API}user/${user1.id}`;
  const URL_GET_MEDIA = `${process.env.REACT_APP_API}findonemedia/profile${user1.id}`;

  useLayoutEffect(() => {
    if (user1.id !== undefined) {
      axios
        .get(URL_GET_INFO, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((data) => setData(data.data))
        .catch((err) => console.log(err));
      axios
        .get(URL_GET_MEDIA, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((data) => setPhoto(data.data))
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line
  }, [user1]);

  return (
    <>
      <div className="headerSchedule">
        <Header />
      </div>
      {isAuth ? (
        <>
          <h1 className="reg">
            <img
              loading="lazy"
              src={`https://res.cloudinary.com/jacobozonana/image/upload/c_crop,g_face,h_1000,w_1000/r_max/c_scale,w_90/v${photo.version}/counselor/profile${user1.id}.png`}
              alt=""
            />{" "}
          </h1>
          <h1 className="reg">Mi Cuenta</h1>
          <Container>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <Row>
                  <Col>
                    <h6>Nombre:</h6>
                  </Col>
                  <Col>
                    <h6> {data.nombre}</h6>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>
                    <h6>Apellido:</h6>
                  </Col>
                  <Col>
                    <h6>{data.apellido}</h6>
                  </Col>
                </Row>
              </ListGroup.Item>
              {user1.role == "user" ?
                <ListGroup.Item>
                  <Row>
                    <Col>
                      <h6>Fecha de nacimiento:</h6>
                    </Col>
                    <Col>
                      <h6>{!data.nacimiento ? '' : dayjs(String(data.nacimiento.split("T")[0])).format('DD/MM/YYYY')}</h6>
                    </Col>
                  </Row>
                </ListGroup.Item>
                : null}
              <ListGroup.Item>
                <Row>
                  <Col>
                    <h6>Celular:</h6>
                  </Col>
                  <Col>
                    <h6>{data.celular}</h6>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>
                    <h6>Email:</h6>
                  </Col>
                  <Col>
                    <h6>{data.email}</h6>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>
                    <h6>Dni:</h6>
                  </Col>
                  <Col>
                    <h6>{data.dni}</h6>
                  </Col>
                </Row>
              </ListGroup.Item>
              {user1.role == "user" ?
                <ListGroup.Item>
                  <Row>
                    <Col>
                      <h6>Cobertura:</h6>
                    </Col>
                    <Col>
                      <h6>{data.obraSocial}</h6>
                    </Col>
                  </Row>
                </ListGroup.Item>
                : null}
              {user1.role == "doctor" ?
                <>
                  <ListGroup.Item>
                    <Row>
                      <h6>Limites de edad de cada paciente:</h6>
                      <Row>
                        <Col>
                          <h6>Inferior</h6>
                          <h6>{data.limiteEdadInferior}</h6>
                        </Col>
                        <Col>
                          <h6>Superior</h6>
                          <h6>{data.limiteEdad}</h6>
                        </Col>
                      </Row>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col>
                        <h6>Limite de turnos por paciente:</h6>
                      </Col>
                      <Col>
                        <h6>{data.limiteCitasPaciente}</h6>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col>
                        <h6>Especialidad:</h6>
                      </Col>
                      <Col>
                        {data.especialidad != undefined ? data.especialidad.map((item) => (
                          <h6>{item}</h6>
                        )) : null}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col>
                        <h6>Obras Sociales Atendida:</h6>
                      </Col>
                      <Col>
                        {data.obraSocialAtendida != undefined ? data.obraSocialAtendida.map((item) => (
                          <h6>{item.nombre}&nbsp;{item.limite} pacientes por semana</h6>
                        )) : null}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                </>
                : null}
            </ListGroup>
          </Container>
          <div className="seccion1">
            <Container className="themed-container" fluid={true}>
              <EditProfile route={user1.role === "user" ? "editusers" : user1.role === "doctor" ? "editdoctors" : user1.role === "admin" ? "editadmins" : null}
                data={data} />
              {/* <DeleteProfile
                  route={"deleteusers"}
                  route1={"schedulesbyuser"}
                  route2={"commentsbyauthor"}
                  route3={"commentsbyabout"}
                /> */}
            </Container>
          </div>
          {/* <Footer /> */}
        </>
      ) : undefined}
    </>
  );
}

export default Profile;