/* eslint-disable */
import React, { useState, useContext, useLayoutEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import Swal from "sweetalert2";
import "../../index.css";

function DaysOff() {
    const { user1, isAuth } = useContext(AuthContext);
    const USERPATCH = `${process.env.REACT_APP_API}editdoctors/${user1.id}/${user1.id}`;
    const USRGET = `${process.env.REACT_APP_API}user/${user1.id}`;
    const hours = ["09:00", "09:15", "09:30", "09:45","10:00","10:15", "10:30", "10:45","11:00","11:15", "11:30", "11:45","12:00", "12:15","12:30", "12:45","13:00","13:15", "13:30", "13:45","14:00","14:15", "14:30","14:45", "15:00","15:15", "15:30", "15:45","16:00","16:15", "16:30", "16:45","17:00","17:15", "17:30","17:45", "18:00"]
    let [a, setA] = useState()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [agendaOff, setAgendaOff] = useState([
        { domingo: false, horasOff: [] },
        { lunes: false, horasOff: [] },
        { martes: false, horasOff: [] },
        { miercoles: false, horasOff: [] },
        { jueves: false, horasOff: [] },
        { viernes: false, horasOff: [] },
        { sabado: false, horasOff: [] }
    ]);

    useLayoutEffect(() => {
        axios.get(USRGET, {
            headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
        })
            .then((data) => (setAgendaOff(data.data.agendaOff)))
            .catch((err) => console.log(err));
    }, []);

    function horas(event) {
        const index = agendaOff[event.target.value].horasOff.includes(event.target.id);
        index ? agendaOff[event.target.value].horasOff = agendaOff[event.target.value].horasOff.filter(hora => hora != event.target.id) : agendaOff[event.target.value].horasOff.push(event.target.id)
        setA(Math.random())
    }

    const editUser = () => {
        Swal.fire({
            title: `La agenda sera editada`,
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            allowEscapeKey: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "Cancelar",
            confirmButtonText: "Confirmar cambios",
        }).then((result) => {
            if (result.isConfirmed) {
                axios.patch(USERPATCH, { agendaOff },
                    {
                        headers: {
                            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                        },
                    }
                )
                    .then((res) => {
                        Swal.fire({
                            icon: "success",
                            title: "Se edito con exito",
                            confirmButtonText: `Ok`,
                            timer: 1000,
                            timerProgressBar: true,
                            allowEscapeKey: true,
                        }).then(() => {
                            window.location.reload();
                            console.log(res);
                        });
                    })
                    .catch((error) => {
                        let message = error.response.data.message;
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Lo sentimos esta acción no se pudo completar " + message,
                            allowEscapeKey: true,
                        });
                    });
            }
        });
    };

    return (
        <>
            {isAuth ? (
                user1.role === "doctor" ? (
                    <>
                        <Button
                            variant="success"
                            onClick={handleShow}
                        >
                            Administra tu agenda
                        </Button>
                        <Modal show={show} size="lg" onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Configura tu agenda</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Text >
                                    Selecciona los dias
                                </Form.Text>
                                <br />
                                <Button size="sm" className="margin" style={{ "minWidth": "6vw" }} variant={agendaOff[0].domingo ? "outline-info" : "info"} onClick={() => agendaOff[0].domingo ? (agendaOff[0].domingo = false, setA(Math.random())) : (agendaOff[0].domingo = true, setA(Math.random()))}>Domingo</Button>
                                {!agendaOff[0].domingo ?
                                    <>
                                        <Form.Text >
                                            Selecciona las horas del domingo
                                        </Form.Text>
                                        <br />
                                        {hours.map((item) => (
                                            <Button variant={agendaOff != [] ? agendaOff[0].horasOff.includes(item) ? "outline-info" : "info" : null} size="sm" className="margin max" id={`${item}`} value={0} style={{ "minWidth": "15vw" }} onClick={(event) => horas(event)}>{item}</Button>
                                        ))}
                                    </>
                                    : null}
                                <br />
                                <Button size="sm" className="margin" style={{ "minWidth": "6vw" }} variant={agendaOff[1].lunes ? "outline-info" : "info"} onClick={() => agendaOff[1].lunes ? (agendaOff[1].lunes = false, setA(Math.random())) : (agendaOff[1].lunes = true, setA(Math.random()))}>Lunes</Button>
                                {!agendaOff[1].lunes ?
                                    <>
                                        <Form.Text >
                                            Selecciona las horas del lunes
                                        </Form.Text>
                                        <br />
                                        {hours.map((item) => (
                                            <Button variant={agendaOff != [] ? agendaOff[1].horasOff.includes(item) ? "outline-info" : "info" : null} size="sm" className="margin max" id={`${item}`} value={1} style={{ "minWidth": "15vw" }} onClick={(event) => horas(event)}>{item}</Button>
                                        ))}
                                    </>
                                    : null}
                                <br />
                                <Button size="sm" className="margin" style={{ "minWidth": "6vw" }} variant={agendaOff[2].martes ? "outline-info" : "info"} onClick={() => agendaOff[2].martes ? (agendaOff[2].martes = false, setA(Math.random())) : (agendaOff[2].martes = true, setA(Math.random()))}>Martes</Button>
                                {!agendaOff[2].martes ?
                                    <>
                                        <Form.Text >
                                            Selecciona las horas del martes
                                        </Form.Text>
                                        <br />
                                        {hours.map((item) => (
                                            <Button variant={agendaOff != [] ? agendaOff[2].horasOff.includes(item) ? "outline-info" : "info" : null} size="sm" className="margin max" id={`${item}`} value={2} style={{ "minWidth": "15vw" }} onClick={(event) => horas(event)}>{item}</Button>
                                        ))}
                                    </>
                                    : null}
                                <br />
                                <Button size="sm" className="margin" style={{ "minWidth": "6vw" }} variant={agendaOff[3].miercoles ? "outline-info" : "info"} onClick={() => agendaOff[3].miercoles ? (agendaOff[3].miercoles = false, setA(Math.random())) : (agendaOff[3].miercoles = true, setA(Math.random()))}>Miercoles</Button>
                                {!agendaOff[3].miercoles ?
                                    <>
                                        <Form.Text >
                                            Selecciona las horas del miercoles
                                        </Form.Text>
                                        <br />
                                        {hours.map((item) => (
                                            <Button variant={agendaOff != [] ? agendaOff[3].horasOff.includes(item) ? "outline-info" : "info" : null} size="sm" className="margin max" id={`${item}`} value={3} style={{ "minWidth": "15vw" }} onClick={(event) => horas(event)}>{item}</Button>
                                        ))}
                                    </>
                                    : null}
                                <br />
                                <Button size="sm" className="margin" style={{ "minWidth": "6vw" }} variant={agendaOff[4].jueves ? "outline-info" : "info"} onClick={() => agendaOff[4].jueves ? (agendaOff[4].jueves = false, setA(Math.random())) : (agendaOff[4].jueves = true, setA(Math.random()))}>Jueves</Button>
                                {!agendaOff[4].jueves ?
                                    <>
                                        <Form.Text >
                                            Selecciona las horas del jueves
                                        </Form.Text>
                                        <br />
                                        {hours.map((item) => (
                                            <Button variant={agendaOff != [] ? agendaOff[4].horasOff.includes(item) ? "outline-info" : "info" : null} size="sm" className="margin max" id={`${item}`} value={4} style={{ "minWidth": "15vw" }} onClick={(event) => horas(event)}>{item}</Button>
                                        ))}
                                    </>
                                    : null}
                                <br />
                                <Button size="sm" className="margin" style={{ "minWidth": "6vw" }} variant={agendaOff[5].viernes ? "outline-info" : "info"} onClick={() => agendaOff[5].viernes ? (agendaOff[5].viernes = false, setA(Math.random())) : (agendaOff[5].viernes = true, setA(Math.random()))}>Viernes</Button>
                                {!agendaOff[5].viernes ?
                                    <>
                                        <Form.Text >
                                            Selecciona las horas del viernes
                                        </Form.Text>
                                        <br />
                                        {hours.map((item) => (
                                            <Button variant={agendaOff != [] ? agendaOff[5].horasOff.includes(item) ? "outline-info" : "info" : null} size="sm" className="margin max" id={`${item}`} value={5} style={{ "minWidth": "15vw" }} onClick={(event) => horas(event)}>{item}</Button>
                                        ))}
                                    </>
                                    : null}
                                <br />
                                <Button size="sm" className="margin" style={{ "minWidth": "6vw" }} variant={agendaOff[6].sabado ? "outline-info" : "info"} onClick={() => agendaOff[6].sabado ? (agendaOff[6].sabado = false, setA(Math.random())) : (agendaOff[6].sabado = true, setA(Math.random()))}>Sabado</Button>
                                {!agendaOff[6].sabado ?
                                    <>
                                        <Form.Text >
                                            Selecciona las horas del sabado
                                        </Form.Text>
                                        <br />
                                        {hours.map((item) => (
                                            <Button variant={agendaOff != [] ? agendaOff[6].horasOff.includes(item) ? "outline-info" : "info" : null} size="sm" className="margin max" id={`${item}`} value={6} style={{ "minWidth": "15vw" }} onClick={(event) => horas(event)}>{item}</Button>
                                        ))}
                                    </>
                                    : null}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={() => editUser()}>Aplicar</Button>
                            </Modal.Footer>
                        </Modal>
                    </>
                ) : null
            ) : null
            }
        </>
    )
}

export default DaysOff