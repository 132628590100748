/* eslint-disable */
import React, { useState, useEffect } from "react";
import {
  Container,
  Button,
  Form,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Header from "../Layout/Header/Header";
import Footer from "../Layout/Footer/Footer";
import "../../index.css";

const Register = () => {
  const query = new URLSearchParams(location.search)
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [dni, setDni] = useState("");
  // const [formulario, setFormulario] = useState(false);
  // const [pacienteExistente, setPacienteExistente] = useState(false);
  const [obraSocial, setObraSocial] = useState("Selecciona la opción adecuada");
  const [numeroObraSocial, setNumeroObraSocial] = useState();
  const [nacimiento, setNacimiento] = useState();
  const [plan, setPlan] = useState();
  const [celular, setCelular] = useState("54911");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const history = useHistory();
  const [file, setFile] = useState();
  let [obras, setObras] = useState([]);
  const titleedbot = `${obraSocial}`;

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}obrassociales/`)
      .then((resDB) => setObras(resDB.data))
      .catch((error) => console.log(error));
  }, []);

  const handleForm = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      Swal.fire({
        icon: "error",
        title: "Verifica tu contraseña",
        allowEscapeKey: true,
      });
    } else {
      const jsonSend = {
        nombre: nombre,
        apellido: apellido,
        email,
        password: dni,
        obraSocial,
        numeroObraSocial,
        nacimiento,
        plan,
        dni,
        celular,
        formulario: query.get("new") === "true" ? false : true,
        pacienteExistente: query.get("new") === "true" ? false : true,
        primeraCita: query.get("new") === "false" ? true : false,
      };

      const SIGNUP_URL = `${process.env.REACT_APP_API}signupuser/`;
      const FILPOST = `${process.env.REACT_APP_API}upprofile/`;

      try {
        await axios.post(SIGNUP_URL, jsonSend).then((data) => {
          if (file) {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("user", data.data._id);
            axios
              .post(FILPOST, formData)
              .then((res) => {
                console.log(res);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
        setNombre("");
        setApellido("");
        setEmail("");
        setPassword("");
        setObraSocial("");
        setNumeroObraSocial("");
        setPlan("");
        setNacimiento(""), setDni("");
        // setFormulario(true);
        setCelular("");
        setFile();
        // setPacienteExistente();
        Swal.fire({
          icon: "success",
          title: "Usuario creado con exito, para poder ingresar tu contraseña sera tu dni",
          text: "Inicia sesión",
          allowEnterKey: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
        }).then(history.push("/"));
      } catch (error) {
        let message = error.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Algo salio mal",
          text: message,
          allowEscapeKey: true,
        });
      }
    }
  };

  return (
    <>
      <Header />
      <Container className="themed-container" fluid={true}>
        <br /><br />
        <h2 className="mb-4 reg">{query.get("new") === "true" ? "Registrate" : "Estamos actualizando nuestra base de datos. Necesitamos pedirte unos datos"}</h2>
        <Form className="container form-regis" onSubmit={handleForm}>
          <Form.Group>
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
              type="text"
              id="nombre"
              name="inputNombre"
              placeholder="Escribe tu nombre como figura en tu documento"
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Apellido</Form.Label>
            <Form.Control
              value={apellido}
              onChange={(e) => setApellido(e.target.value)}
              type="text"
              name="apellido"
              id="inputApellido"
              placeholder="Escribe tu apellido como figura en tu documento"
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Número de documento</Form.Label>
            <Form.Control
              value={dni}
              onChange={(e) => setDni(e.target.value)}
              type="number"
              name="dni"
              id="exampleDni"
              placeholder="Dni"
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Fecha de nacimiento</Form.Label>
            <Form.Control
              value={nacimiento}
              onChange={(e) => setNacimiento(e.target.value)}
              type="date"
              name="nacimiento"
              id="exampleNacimiento"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Celular</Form.Label>
            <Form.Control
              value={celular}
              onChange={(e) => setCelular(e.target.value)}
              // type="number"
              name="celular"
              id="inputcelular"
              placeholder="Celular"
              required
              type="text"
            // pattern="[1-9]{1}[0-9]{9}"
            />
          </Form.Group>
          {/* <Form.Group>
            <Form.Label>Foto</Form.Label>
            <Form.File>
              <Form.File.Input
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
              />
            </Form.File>
          </Form.Group> */}
          <Form.Group>
            <Form.Label>Cobertura</Form.Label>
            <DropdownButton variant="outline-info" title={titleedbot}>
              {obras.map((obra, i) => (
                <Dropdown.Item
                  onClick={() => {
                    setObraSocial(obra.nombre);
                  }}
                  key={i}
                >
                  <h4 className="alineacion">{obra.nombre}</h4>
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </Form.Group>
          <Form.Group>
            <Form.Label>N° de Afiliado</Form.Label>
            <Form.Control
              value={numeroObraSocial}
              onChange={(e) => setNumeroObraSocial(e.target.value)}
              type="text"
              name="numeroObraSocial"
              id="exampleNumeroObraSocial"
              placeholder="¿Cuál es tu n° de afiliado?"
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Plan</Form.Label>
            <Form.Control
              value={plan}
              onChange={(e) => setPlan(e.target.value)}
              type="number"
              name="plan"
              id="examplePlan"
              placeholder="¿Cuál es su plan?"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              name="email"
              id="exampleEmail"
              placeholder="Correo electronico"
              required
              className="camposForm"
            />
          </Form.Group>
          {/* <label>Ya eres paciente del consultorio Odin?</label>
          <input type="checkbox" onChange={() => { pacienteExistente ? setPacienteExistente(false) : setPacienteExistente(true); formulario ? setFormulario(false) : setFormulario(true) }} /> */}
          {/* <Form.Group>
            <Form.Label>Eres paciente del consultorio Odin?</Form.Label>
            <Form.Check
              onChange={() => {
                pacienteExistente ? setPacienteExistente(false) : setPacienteExistente(true)
                formulario ? setFormulario(false) : setFormulario(true)
              }}
              type="switch"
              name="formulario"
              id="exampleformulario"
              label={formulario ? "Sí, ya soy paciente" : null}
            />
          </Form.Group> */}
          {/* <Form.Group>
            <Form.Label>Contraseña</Form.Label>
            <Form.Control
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              name="password"
              id="examplePassword"
              placeholder="Escribe tu contraseña"
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Confirma la contraseña</Form.Label>
            <Form.Control
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              type="password"
              name="password"
              id="examplePassword"
              placeholder="Escribe tu contraseña"
              required
            />
          </Form.Group> */}
          <br /><br />
          <h4 className="mb-4 reg">Tu contraseña sera tu dni</h4>
          <Button variant="info" type="submit">
            Enviar
          </Button>
        </Form>
      </Container>
      {/* <Footer /> */}
    </>
  );
};

export default Register;
