/* eslint-disable */
import React, { useState, useLayoutEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Button, Form, } from 'react-bootstrap'
import Swal from "sweetalert2";
import axios from 'axios'
import base64 from 'base-64';
import ReactLoading from 'react-loading'
import Logo from '../../Counselor/Logo.jpg'
import './ForgotPassword.css'

function ForgotPassword(props) {
  let location = useLocation()
  let params = new URLSearchParams(location.search)
  let [loading, setLoading] = useState(true)
  let [loading2, setLoading2] = useState(false)
  let [error, setError] = useState(false)

  let [userData, setUserData] = useState({})
  let [pass1, setPass1] = useState()
  let [pass2, setPass2] = useState()

  function handleSubmit() {
    setLoading2(true)

    if (!pass1 || !pass2) { Swal.fire({ icon: "error", iconColor: 'red', text: 'No has llenado todos los campos, revisa los datos e intenta de nuevo.' }); setLoading2(false) }
    else if (pass1 !== pass2) { Swal.fire({ icon: "error", iconColor: 'red', text: 'Las contraseñas no coinciden, revisa los datos e intenta de nuevo.' }); setLoading2(false) }
    else {
      axios.patch(`${process.env.REACT_APP_API}changePass2`, { dni: userData.dni, mario: userData.mario, newpassword: pass1 })
        .then(() => { Swal.fire('Contraseña actualizada con exito!').then(() => { setLoading2(false); window.location.replace('https://odinturnos.netlify.app/') }) })
        .catch((err) => { console.log(err); Swal.fire('Ocurrio un error, intenta de nuevo por favor.'); setLoading2(false) })
    }
  }

  useLayoutEffect(() => {
    if (params.get("LNK")) {
      let decoded = (JSON.parse(base64.decode(params.get("LNK"))))

      if (new Date().getTime() > decoded.time) { setError(true) }

      setLoading(false); setUserData(decoded)
    }
  }, [])

  return (
    <>
      {
        !loading ?
          <>
            <br /><br />
            <div className='contenidosForgot'>
              <img className="img-fluid" src={Logo} alt="" />

              <br />

              {
                !error ?
                  <>
                    <div style={{ width: '50%', margin: 'auto', padding: '5%' }}>

                      <p>Reestablece tu contraseña para ingresar al portal</p>

                      <br /><br />

                      <Form.Control type="password" id="exampleFormControlInput1" label="Contraseña Nueva" placeholder="Escribe tu nueva contraseña" aria-describedby="exampleFormControlInputHelpInline"
                        onChange={(e) => { setPass1(e.target.value) }}
                      />

                      <br />

                      <Form.Control type="password" id="exampleFormControlInput1" label='Repite tu Contraseña Nueva' placeholder="Confirma tu nueva contraseña" aria-describedby="exampleFormControlInputHelpInline"
                        onChange={(e) => { setPass2(e.target.value) }}
                      />

                      <br /><br /><br />

                      {
                        !loading2 ?
                          <><Button color="info" onClick={() => { handleSubmit() }}>Reestablecer la contraseña</Button></>
                          :
                          <><div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div></>
                      }

                    </div>
                  </>
                  :
                  <><p style={{ "fontWeight": "bold", "color": "red", 'padding': '5%' }}>Ocurrio un error, por favor intetenta nuevamente.</p></>
              }

            </div>
          </>
          : <><div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div></>
      }
    </>
  );
}

export default ForgotPassword